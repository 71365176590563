import React, {ReactNode, useState} from 'react';
import { FaMinus, FaPlus } from "react-icons/fa";


interface IOrderHeaderBar {
    title:string,
    children?:ReactNode
}

const OrderHeaderBar :React.FC<IOrderHeaderBar> = ({children,title})=>{
    return (
        <div className="flex items-center">
 <div className="flex items-center relative">
            <div className="border border-DARKEST_GREY_L3 rounded-full p-1 lg:p-2">

            <FaMinus className='' />
            </div>
            <h1 className="font-semibold lg:text-xl xl:text-[22px] text-texthero lg: pl-2">
           {title}
            </h1>
            </div>
            {children}
        </div>
    )
}

export default OrderHeaderBar;