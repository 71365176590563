import { gql } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { APOLLO_CLIENT_URI } from '../../config/config';


const client = new ApolloClient({
  uri:  `${APOLLO_CLIENT_URI}graphql`,
  cache: new InMemoryCache(),
});

export const ADD_BOOKING_CUSTOMER_DETAILS = gql`
  mutation AddBookingCustomerDetails(
    $userId: ID!, 
    $fullName: String, 
    $gender: String,
   
    $emailId: String, 
    $phoneNumber: String
  ) {
    addBookingCustomerDetails(
      user_id: $userId, 
      full_name: $fullName, 
      gender: $gender, 
   
      email_id: $emailId, 
      phone_number: $phoneNumber
    ) {
      booking_customer_detail_id
      full_name
      gender
      phone_number
      date_of_birth
      user_id
    }
  }
`;


export const ADD_USER = gql`
  mutation AddUser(
    $email_id: String!
    $phone_number: String!
    $first_name: String!
    $dob: String!
    $gender: String!
    $referral_code: String
  ) {
    addUser(
      email_id: $email_id
      phone_number: $phone_number
      first_name: $first_name
      dob: $dob
      gender: $gender
      referral_code: $referral_code
    ) {
      user {
        user_id
        email_id
        phone_number
        first_name
        dob
        gender
        referral_code
      }
      token
      message
    }
  }
`;

export const LOGIN_WITH_MOBILE = gql`
  mutation LoginWithMobile($mobile: String!, $otp: String) {
    loginWithMobile(mobile: $mobile, otp: $otp) {
      user {
        user_id
        email_id
        phone_number
        first_name
        dob
        gender
        referral_code
      }
      token
      message
    }
  }
`;

export const GET_MOBILE_OTP = gql`
  mutation GetMobileOtp($mobile: String!) {
    getMobileOtp(mobile: $mobile) {
      message
    }
  }
`;

export { client };  
