import React, { useCallback, useEffect, useState } from "react";
import SalonCard from "../Ui/SalonCard";
import axios from "axios";
import { setallsalondata } from "../../store/slices/salonSlice";
import { useDispatch, useSelector } from "react-redux";
interface Salon {
  shop_id: string;
  shop_name: string;
  profile_image_url?: string[];
  rating: number;
  isOpen: boolean;
  location?: string;
  distance: string | null;
  salonType: string;
  holiday: string;
  address: string;
  offer_message: string;
  review_count: number;
  average_rating: number;
  customer_category: string[];
  shop_weekly_timings: { [key: string]: string };
  locations?: {
    maps_link: string;
    city: string;
    state: string;
    address: string;
    pincode: string;
  };
}
const RecentlyViewedSalons: React.FC = () => {
  const [salonData, setSalonData] = useState<Salon[]>([]);
  const getRecentlyViewed = useCallback((): Salon[] => {
    try {
      const existing = localStorage.getItem("recentlyViewedSalons");
      return existing ? JSON.parse(existing) : [];
    } catch (error) {
      console.error("Error fetching recently viewed salons:", error);
      return [];
    }
  }, []);

  useEffect(() => {
    const viewed: Salon[] = getRecentlyViewed();
    setSalonData(viewed);
  }, [getRecentlyViewed]);
  return (
    <React.Fragment>
      {salonData?.length > 0 && (
        <div className="pt-20 h-full mx-5 md:mx-7 lg:mx-16 xl:mx-24  md:pt-20   ">
          <div className="flex items-center md:pt-5 lg:pb-8">
            <h1 className="font-medium max-w-[1200px]  lg:font-normal text-xl md:text-2xl lg:text-4xl xl:text-[45px] text-texthero text-left">
              Recently Viewed Salons
            </h1>
            <div className="flex flex-1"></div>
            <div className="flex items-center justify-end gap-1 lg:gap-2">
              {/* <p className="text-sm lg:text-base text-texthero">View All</p>   */}
              {/* <FaAngleRight className="text-sm lg:text-base font-light text-DARKEST_GREY_L1" /> */}
            </div>
          </div>
          <div className="w-full overflow-scroll hideScrollBar lg : flex items-center xl:m-auto xl:justify-center gap-5 pt-5 md:pt-5 lg:pt-0">
            <SalonCard
              salonData={salonData}
              className="gradient -border h-full w-[15rem] md:w-[355px] lg:w-[370px] xl:w-[400px]"
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default RecentlyViewedSalons;
