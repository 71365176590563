import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustServicePop, SubCategory } from '../../types/salonServicesTypes';


interface Customization {
  sub_customization_id: string;
  sub_customization_name: string;
  service_cost: number;
  discount_price: number;
  alloted_time: string;
  description: string;
  shop_sub_category_id: string;
}

interface ICustServiceProp {
  customizations: CustServicePop[];
  category_name: string;
}

interface SalonDetailsState {
  loading: boolean;
  error: string | null;
  subCategories: SubCategory[] | null;
  custServices: ICustServiceProp | null;
  serviceType: string;
  subCustomisation: Customization | null;
 
}

const initialState: SalonDetailsState = {
  loading: true,
  error: null,
  subCategories: null,
  custServices: null,
  serviceType: 'Short Hair',
  subCustomisation: null,
 
};


const salonDetailsSlice = createSlice({
  name: 'salonDetails',
  initialState,
  reducers: {
    setSubCustomization: (state, action: PayloadAction<Customization | null>) => {
      state.subCustomisation = action.payload; 
    },
    clearSubCustomization: (state) => {
      state.subCustomisation = null;
    },
    fetchSubCategoriesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchSubCategoriesSuccess(state, action: PayloadAction<SubCategory[]>) {
      state.loading = false;
      state.subCategories = action.payload;
    },
    fetchSubCategoriesFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchCustServices (state,action:PayloadAction<ICustServiceProp>) {
      state.loading = false;
      state.custServices = action.payload;
    },
    fetchServiceType (state,action:PayloadAction<string>) {
      state.loading = false;
      state.serviceType = action.payload;
    },
  },
});

export const {
  fetchSubCategoriesStart,
  fetchSubCategoriesSuccess,
  fetchSubCategoriesFailure,
  fetchCustServices,
  fetchServiceType,
  setSubCustomization, 
  clearSubCustomization,
} = salonDetailsSlice.actions;

export default salonDetailsSlice.reducer;
