import React from "react";
import { getBrandColor } from "../../lib/brandService";
import { LuDot } from "react-icons/lu";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useParams } from "react-router-dom";

export const ServiceSummary: React.FC = () => {
  const brandColor = getBrandColor();
  const bookingDetails = useSelector(
    (state: RootState) => state.booking.bookingDetails
  );
  const shopName = useSelector(
    (state: RootState) => state.salonDetails.data?.shop_name
  );

  const params = useParams();
  const salonName: string = params.shopName ?? "";

  const services = [
    {
      title: "Services",
      services: bookingDetails?.services_ids ?? [],
    },
    {
      title: "Packages",
      services: bookingDetails?.packages_ids ?? [],
    },
  ];
  return (
    <div className="hidden md:block bg-DARKEST_GREY_L4 rounded-md md:p-6 md:w-72 lg:w-96  lg:max-w-[40%] md:mt-6">
      <h3
        className=""
        style={{
          color: brandColor,
        }}
      >
        BOOKING SUMMARY
      </h3>
      {bookingDetails?.booking_id ? (
        <>
          <h3 className="text-DARKEST_GREY_L2 md:py-4">
            BOOKING ID:{" "}
            <span className="font-semibold pl-1 text-texthero uppercase ">
              #ODSTY{bookingDetails?.booking_id}
              {salonName.slice(0, 3)}
            </span>
          </h3>
          <div className="">
            {services.map((item, key) => (
              <div className={`${item.services.length < 1 && "hidden"}`}>
                <h2 className="">{item.title}</h2>
                <div className="flex items-center">
                  {item.services?.map((service, key) => (
                    <div
                      key={key}
                      className={`  flex items-center  py-2 text-DARKEST_GREY_L3 font-medium `}
                    >
                      <LuDot className="text-xl" />
                      <p className="">{service}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <hr className="text-DARKEST_GREY_L3" />
          <div className="flex items-center text-DARKEST_GREY_L2 py-3">
            <h3 className="">{bookingDetails?.appointment_date}</h3>
            <div className="flex flex-1" />
            <p className="">{bookingDetails?.slot_timings[0]}</p>
          </div>
          <div className=""></div>
          <hr className="text-DARKEST_GREY_L3" />
          <div className="text-DARKEST_GREY_L2 py-3 ">
            <p className="text-DARKEST_GREY_L1">{shopName}</p>
          </div>
        </>
      ) : (
        <div>
          <p>no bookings found</p>
        </div>
      )}
    </div>
  );
};
