import React, { useState } from "react";
import { IInputPPProps } from "../../types/userAuthTypes";

const InputPP: React.FC<IInputPPProps> = ({
  label,
  type,
  name = "",
  maxLength,
  placeholder,
  value,
  isCustClass,
  custClassName = "",
  onChange = () => {},
  onError = () => {},
  istextArea = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const textboxClass = `border border-DARKEST_GREY_L3 rounded-2xl w-full text-texthero leading-tight focus:outline-none`;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = e.target.value;
    onChange(inputValue);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (type === "date") {
      e.target.type = "date"; // Change type to date on focus
      e.currentTarget.showPicker(); // Open the date picker on click
   
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (type === "date") {
      // e.currentTarget.showPicker(); // Open the date picker on click
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (type === "date" && !e.target.value) {
      e.target.type = "text"; // Revert type if no date is selected
    }
  };

  return (
    <div>
      <h2 className={`${(type!=='date'||isCustClass)&&'hidden'} text-sm text-medium px-1 pb-1`}>
        Enter Your Date of Birth
      </h2>
      <input
        type={  type} // Start as text to show DOB
        name={name}
        maxLength={maxLength}
        className={`${istextArea ? "hidden" : ""} ${custClassName} ${textboxClass}`}
        placeholder={  placeholder}
        value={value ?? ""}
        onChange={handleInputChange}
        // onFocus={handleFocus}
        // onClick={handleClick} // Trigger showPicker on click
      />
      <textarea
        value={value ?? ""}
        placeholder={placeholder}
        onChange={handleInputChange}
        className={`${!istextArea ? "hidden" : ""} ${custClassName} ${textboxClass} h-28 py-3 px-3`}
      />
    </div>
  );
};

export default InputPP;
