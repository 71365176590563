import React from 'react';

interface EmojiPictureProps {
    gifSrc: string;
    altText: string;
    size: number;
    isActive: boolean;
    onClick: () => void;
  }
  
  const EmojiPicture: React.FC<EmojiPictureProps> = ({  gifSrc, altText, size, isActive, onClick }) => (
    <picture
      className={`transition-transform duration-300 ease-in-out cursor-pointer ${isActive ? 'filter-none' : 'filter grayscale'} hover:filter-none`}
      onClick={onClick}
    >
    <source srcSet={`https://fonts.gstatic.com/s/e/notoemoji/latest/${gifSrc}.webp`} type="image/webp" />
    <img src={`https://fonts.gstatic.com/s/e/notoemoji/latest/${gifSrc}.gif`} alt={altText} width={size} height={size} className='' />
  </picture>
);

export default EmojiPicture;
