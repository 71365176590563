import React, { useState } from "react";
 
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { faqData } from "../../data/faqData";

// Define types for the FAQ data structure
interface FaqItem {
    id: number;
    title: string;
    des: string;
}

const FAQ: React.FC = () => {
    const [activeTab, setActiveTab] = useState<number | null>(0);
    const [isShow, setIsShow] = useState<boolean>(true);

    return (
        <main className="text-texthero">
            <h1 className="md:text-2xl lg:text-4xl font-semibold text-center py-6 md:py-10 lg:py-20">
                FAQs
            </h1>

            {faqData.map((item: FaqItem) => (
                <div key={item.id} className="pb-3 relative md:pb-5 lg:pb-10">
                    <div
                        className=""
                        onClick={() => {
                            if (activeTab === item.id) {
                                setActiveTab(0); // Collapse the current tab if already open
                            } else {
                                setActiveTab(item.id); // Set the active tab
                            }
                        }}
                    >
                        <div className="relative w-full flex items-center pb-2 md:pb-3 lg:pb-5 cursor-pointer">
                            <div>
                                <h2 className="text-[12px] md:text-base lg:text-xl capitalize">
                                    {item.title}
                                </h2>
                            </div>
                            <div className="flex flex-1"></div>
                            <div>
                                <IoIosArrowUp
                                    className={`${activeTab === item.id ? "" : "hidden"} md:text-3xl abs olute top-0 right-0`}
                                />
                                <IoIosArrowDown
                                    className={`${activeTab === item.id ? "hidden" : ""} md:text-3xl absol ute right-0`}
                                />
                            </div>
                        </div>

                        {activeTab === item.id && (
                            <p className={`${isShow ? "" : "hidden"} text-[11px] md:text-base lg:text-lg pb-2`}>
                                {item.des}
                            </p>
                        )}
                    </div>
                    <hr />
                </div>
            ))}
        </main>
    );
};

export default FAQ;
