import React, { useEffect } from "react";
import img1 from "../../assets/booking/status2.png";
import { useSelector,useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import BookingStatusCard from "../Ui/BookingStatusCard";
import { RootState } from '../../store/store';
import { bookingStateValues } from "../../config/parameters";
import { setBookingState } from "../../store/slices/bookingSlice";

type bookinSuccess = {
  title: string;
};

const BookingSuccess: React.FC<bookinSuccess> = ({ title }) => {
    const dispatch = useDispatch();
    const {bookingState} = useSelector((state: RootState) => state.booking);
  const propValue = {
    title: title,
    description:
      " The booking summary will be sent to WhatsApp. HAPPY STYLING!",

    isSpec: true,
    imgUrl: img1,
  };

  useEffect(()=>{
    setTimeout(() => {
        
        if(bookingState===bookingStateValues[1] ) {
            dispatch(setBookingState(bookingStateValues[4] ))
            
        }
    }, 2000);
  },[])

  return (
    <section className=" ">
      <BookingStatusCard statusObj={propValue} />
    </section>
  );
};

export default BookingSuccess;
