import React from 'react';
import image from '../../assets/homeFeatures/img1.png';

// Type for inline style
const textGrad: React.CSSProperties = {
    background: "var(--Golden-Crust, linear-gradient(84deg, #BF963E 3.41%, #FFFBA4 47.6%, #AD832D 91.34%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
};

// Functional component definition
const StylinForBusiness: React.FC = () => {
    return (
        <main className="bg-texthero w-full py-10 lg:py-24 px-14 lg:px-36 flex flex-col-reverse lg:flex-row items-center">
            <div className="pt-7 lg:pt-0">
                <div className="text-2xl lg:text-5xl m-auto lg:m-0 w-full text-center lg:text-start space-y-2">
                    <h1 className="font-michroma" style={textGrad}>
                        STYLIN
                    </h1>
                    <h4 className="text-white font-semibold">
                        For Business
                    </h4>
                </div>
                <p className="text-DARKEST_GREY_L3 text-center lg:text-start lg:text-xl lg:w-[65%] py-7 lg:py-10">
                    As we revolutionize the landscape of styling, we invite passionate professionals and forward-thinking businesses to partner with us on this transformative journey.
                </p>
                <button className="bg-white font-medium px-3 py-1.5 m-auto lg:m-0 flex lg:px-6 lg:py-3 rounded-md">
                    List your Business
                </button>
            </div>
            <img
                src={image}
                className="md:block rounded-[12px] w-auto md:w-full md:max-w-[40%] lg:w-full object-fill"
                alt="img"
            />
        </main>
    );
};

export default StylinForBusiness;
