import img1 from "../assets/homeFeatures/whyStylin/img1.svg";
import img2 from "../assets/homeFeatures/whyStylin/img2.svg";
import img3 from "../assets/homeFeatures/whyStylin/img3.svg";
import img4 from "../assets/homeFeatures/whyStylin/img4.svg";

// Define the data structure for WhyStylinCard
export const whyStylindata = [
    {
        id: 1,
        imgUrl: img1,
        title: "Effortless Discovery",
        des: "Simply find and explore the best salons based on your preference and requirements",
    },
    {
        id: 2,
        imgUrl: img2,
        title: "Convenient Booking",
        des: "With just a few clicks, schedule your appointments seamlessly and hassle-free",
    },
    {
        id: 3,
        imgUrl: img3,
        title: "Competitive Prices",
        des: "Unlock special offers with every use, gaining points and rewards on the way",
    },
    {
        id: 4,
        imgUrl: img4,
        title: "Enhanced Experience",
        des: "Enhance your salon experience with transparent high-quality services",
    },
];
