import React from 'react';
import GrowTogCard from '../Ui/GrowTogetherCard';
import { growTogData } from '../../data/growTogetherData';


const GrowTog: React.FC = () => {
    return (
        <main className="">
            <h1 className="font-medium lg:font-normal text-xl md:text-2xl lg:text-4xl xl:text-[45px] text-texthero text-center">
                Growing Together
            </h1>

            <div className="pt-7 lg:pt-14">
                <GrowTogCard growtogData={growTogData} />
            </div>
        </main>
    );
};

export default GrowTog;
