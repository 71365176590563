import React, { useState,useEffect } from "react";
import { GoDotFill } from "react-icons/go";

 
import axios from 'axios';
import { Link } from 'react-router-dom';

interface BusinessButton {
    id: number;
    btnNm: string;
}

const BusinessForm: React.FC = () => {
    const [activeBtn, setActiveBtn] = useState<number>(2);
    const businessBtn: BusinessButton[] = [
        {
            id: 2,
            btnNm: "For Brands",
        },
    ];

    return (
        <main className="2xl:w-[1700px] 2xl:m-auto">
            {/* <div className="flex justify-center bg-white 
            ">
                {businessBtn.map((item) => (
                    <div
                        key={item.id}
                        className={`${
                            activeBtn === item.id
                                ? "bg-hero text-white"
                                : "bg-white"
                        } rounded-md drop-shadow-lg px-3 lg:px-5 py-1 lg:py-2`}
                        onClick={() => {
                            setActiveBtn(item.id);
                        }}
                    >
                        <button className="lg:text-lg font-medium font-lexend">
                            {item.btnNm}
                        </button>
                    </div>
                ))}
            </div> */}
            <div className="pt-20 lg:py-14">

            <div className="pt-5 lg:pt-0 pb-10 lg:pb-20">
                <Section1 activeTab={[activeBtn, setActiveBtn]} />
            </div>
            </div>
        </main>
    );
};

export default BusinessForm;



 

interface FormData {
    id: number;
    label: string;
    name: string;
    required: boolean;
    isDrop?: boolean;
    options?: string[];
    isReq?: boolean;
}

interface Section1Props {
    activeTab: [number, React.Dispatch<React.SetStateAction<number>>];
}

const Section1: React.FC<Section1Props> = ({ activeTab }) => {
    const [activeTabs, setActiveTabs] = activeTab;
    const [subSecCont, setSubSecCont] = useState<string[]>([]);

    const salonformData: FormData[] = [
        { id: 1, label: "Full Name ", name: "fullname", required: true },
        { id: 2, label: "Whatsapp Number ", name: "contactno", required: true },
        { id: 3, label: "Salon Name ", name: "salonname", required: true },
        {
            id: 4,
            label: "Salon Type ",
            isDrop: true,
            name: "salontype",
            options: [" Male", "Female", "Unisex"],
            required: true,
        },
        { id: 5, label: "Email ", name: "email", isReq: false, required: false },
        { id: 6, label: "Location Link/Address", name: "location", required: true },
        { id: 7, label: "Years in Operation", name: "yearsinope", required: true },
        { id: 8, label: "Social Media/Website Link ", name: "socialmedia", isReq: false, required: false },
    ];

    const brandformData: FormData[] = [
        { id: 1, label: "Full Name ", name: "fullname", required: true },
        { id: 2, label: "Whatsapp Number ", name: "contactno", required: true },
        { id: 3, label: "Brand Name ", name: "brandname", required: true },
        {
            id: 4,
            label: "Brand Type ",
            isDrop: true,
            name: "brandtype",
            required: true,
        },
        { id: 5, label: "Email ", name: "email", required: true },
        { id: 6, label: "Place", name: "place", required: true },
        { id: 7, label: "Years in Operation", name: "yearsinope", required: true },
        { id: 8, label: "Social Media/Website Link ", name: "socialmedia", required: true },
    ];

    const salonTitle = "Schedule Your Exclusive Salon Experience";
    const brandTitle = "Transform your brand’s journey with us";

    useEffect(() => {
        const salonSubDat = [
            " A personalized, live walkthrough and demo tailored to your salon's unique identity",
            "Witness firsthand how STYLIN seamlessly integrates AI and AR to revolutionize the beauty experience.",
            "Uncover bespoke solutions crafted to enhance your salon's performance.",
            "Engage in an interactive session, addressing your specific needs and challenges",
        ];
        const brandSubDat = [
            "Witness our demo walkthrough with Stylin’s innovative features for your brand’s outreach and growth.",
            "Explore tailored solutions designed to elevate your brand's performance in the digital realm.",
            "Engage in an interactive session to discuss how influencers can collaborate with brands and contribute to each other’s growth.",
            "Explore how STYLIN can amplify your brand presence and create immersive experiences for your audience.",
        ];
        setSubSecCont(activeTabs === 1 ? salonSubDat : brandSubDat);
    }, [activeTabs]);

    return (
        <div className="flex flex-col lg:flex-row xl:gap -8 w-full px-5 lg:px-14 xl:px-20">
            <div>
                <h1 className="xl:w-full xl:w-max-[90%] md:text-center capitalize lg:text-start py-5 lg:py-0 text-2xl lg:text-3xl xl:text-5xl font-lexend font-semibold lg:leading-[1.3] xl:leading-[1.5]">
                    {activeTabs === 1 ? salonTitle : brandTitle}
                </h1>
                <h5 className="xl:text-xl hidden lg:block py-3">
                    {activeTabs === 1 ? "Let’s connect for:" : "We’ll Connect for:"}
                </h5>
                {subSecCont.map((item, key) => (
                    <p key={key} className="hidden lg:flex lg:w-96 xl:w-full items-start py-2 gap-3">
                        <GoDotFill className="lg:text-xl lg:mt-1" />
                        <span className="xl:text-xl">{item}</span>
                    </p>
                ))}
            </div>
            <div className="w-full">
                <ContactusForm
                    custClass="md:w-[30rem] lg:w-[85%]"
                    formData={activeTabs === 1 ? salonformData : brandformData}
                    typeReq={activeTabs === 1 ? "salonform" : "brandform"}
                    dropValue={
                        activeTabs === 1
                            ? ["Select Salon type", " Male", "Female", "Unisex"]
                            : [
                                  "Select Brand type",
                                  "Salon",
                                  "Inventory",
                                  " Beauty Products",
                                  "Salon Products",
                                  "Others",
                              ]
                    }
                />
            </div>
        </div>
    );
};



interface FormField {
    name: string;
    label: string;
    required?: boolean;
    placeholder?: string;
    isDrop?: boolean;
    isReq?: boolean;
}

interface ContactusFormProps {
    formData: FormField[];
    dropValue: string[];
    typeReq: string;
    custClass?: string;
}

export const ContactusForm: React.FC<ContactusFormProps> = ({
    formData = [],
    dropValue = [],
    typeReq,
    custClass = 'w-[50%]',
}) => {
    const [inputs, setInputs] = useState<Record<string, string>>({});
    const [formErrors, setFormErrors] = useState<Record<string, string | undefined>>({});
    const [messageError, setMessageError] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [formtype, setFormtype] = useState<string>(typeReq);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, fieldName: string) => {
        setInputs((prev) => ({
            ...prev,
            [fieldName]: e.target.value,
        }));
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: undefined,
        }));
    };

    const validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const validateField = (fieldName: string, value: string | undefined) => {
        const field = formData.find((f) => f.name === fieldName);
        if (field?.required && !value) {
            return false;
        }
        return true;
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors: Record<string, string> = {};

        for (const field of formData) {
            const value = inputs[field.name];
            if (!validateField(field.name, value)) {
                newErrors[field.name] = `${field.label} is required`;
                isValid = false;
            }
        }

        setFormErrors(newErrors);
        return isValid;
    };

    useEffect(() => {
        if (formtype === "b" || formtype === "s") {
            setInputs({});
        }
    }, [formtype]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            const salondata = {
                fullname: inputs.fullname,
                whatsapp: inputs.contactno,
                salonname: inputs.salonname,
                salontype: inputs.salontype,
                email: inputs.email,
                location: inputs.location,
                yearsinoperation: inputs.yearsinope,
                medialink: inputs.socialmedia,
                message: inputs.message,
            };

            const branddata = {
                fullname: inputs.fullname,
                whatsapp: inputs.contactno,
                brandname: inputs.brandname,
                brandtype: inputs.brandtype,
                email: inputs.email,
                location: inputs.place,
                yearsinoperation: inputs.yearsinope,
                medialink: inputs.socialmedia,
                message: inputs.message,
            };

            const featureddata = {
                fullname: inputs.fullname,
                email: inputs.email,
                number: inputs.mobile,
                state: inputs.state,
                message: inputs.message,
            };

            const apiEndpoints: { [key: string]: string } = {
                salonform: "https://sheetdb.io/api/v1/8js046f57oxan",
                brandform: "https://sheetdb.io/api/v1/asjghevkytrbl",
                featuredform: "https://sheetdb.io/api/v1/77v7drt9if80x",
            };

            if (Object.keys(inputs).length > formData.length && apiEndpoints[typeReq]) {
                const formDataToSubmit = typeReq === "salonform" ? salondata : typeReq === "brandform" ? branddata : featureddata;
                axios
                    .post(apiEndpoints[typeReq], formDataToSubmit)
                    .then((res) => {
                        if (res.status === 201) {
                            setShowSuccessModal(true);
                            window.scrollTo({ top: 0, behavior: "smooth" });
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }

        setMessageError(!Object.keys(inputs).includes("message"));
    };

    return (
        <>
            {showSuccessModal && (
                <div className="absolute top-0 h-screen m-auto flex justify-center bg-[#020202b8] z-10 w-full left-0">
                    {/* <SuccessModal
                        triggerButtonRef={handleSubmit}
                        closeBox={[showSuccessModal, setShowSuccessModal]}
                    /> */}
                </div>
            )}

            <form className={`bg-white ${custClass} -z-0 m-auto text-[#020202] p-4 lg:p-7 rounded-lg drop-shadow-lg`} onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                    {formData.map((item) => (
                        <div key={item.name} className="flex flex-col space-y-2">
                            <label htmlFor={item.name} className={`text-base font-lexend ${item.isDrop ? "hidden" : ""}`}>
                                {item.label}
                                {item.isReq !== false && <span className="text-[#de594a]">*</span>}
                            </label>

                            <input
                                type="text"
                                id={item.name}
                                name={item.name}
                                value={inputs[item.name] || ""}
                                placeholder={item.placeholder}
                                className={`${item.isDrop ? "hidden" : ""} border rounded-md drop-shadow-md mt-2 py-1 px-2 lg:w-60 border-[#625f5f6e] focus:outline-none`}
                                onChange={(e) => handleChange(e, item.name)}
                            />

                            {item.isDrop && (
                                <>
                                    <label className="font-lexend" htmlFor={item.name}>
                                        {item.label}<span className="text-[#de594a]">*</span>
                                    </label>
                                    <select
                                        id={item.name}
                                        className={`mt-2 border rounded-md drop-shadow-md py-1 px-2 lg:w-60 border-[#625f5f6e] focus:outline-none`}
                                        onChange={(e) => handleChange(e, item.name)}
                                    >
                                        {dropValue.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            )}
                            {formErrors[item.name] && <span style={{ color: 'red' }}>{formErrors[item.name]}</span>}
                        </div>
                    ))}
                </div>

                <div className="pt-5 space-y-2">
                    <label htmlFor="message" className="font-lexend">
                        Your Message <span className="text-[#de594a]">*</span>
                    </label>
                    <textarea
                        name="message"
                        id="message"
                        // cols={30}
                        // rows={10}
                        value={inputs["message"] || ""}
                        // onChange={(e:React.FormEvent) => handleChange(e, "message")}
                        className="border rounded-md drop-shadow-md w-full py-1 px-2 lg:w-full border-[#625f5f6e] focus:outline-none"
                    />
                    {messageError && <span style={{ color: 'red' }}>Message is required</span>}
                </div>

                {/* <div className="flex gap-4 py-2 items-center">
                    <input
                        type="checkbox"
                        className="text-lg"
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    <p className="text-lg font-mukta">
                        I agree with <span className="text-[#527fde] underline"><Link to="/terms">Terms of Service</Link></span> and <span className="text-[#527fde] underline"><Link to="/privacy">Privacy Policy</Link></span>
                    </p>
                </div> */}

                {/* <div className="flex gap-4 py-2 items-center">
                    <input type="checkbox" className="text-lg" />
                    <p className="text-lg font-mukta">
                        I would like to receive the latest information about service updates and special offers from Stylin
                    </p>
                </div> */}

                <button
                    type="submit"
                    disabled={!isChecked}
                    className={`border py-1 px-4 lg:py-1.5 lg:px-6 m-auto flex rounded-md ${isChecked ? "hover:bg-hero bg-[#363434]" : "bg-[#6e6767] cursor-not-allowed"} text-white font-semibold font-lexend text-sm`}
                >
                    Submit
                </button>
            </form>
        </>
    );
};
