import { Link, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { FaStar, FaRegCalendarAlt } from "react-icons/fa";
import { MdOutlineLocationOn } from "react-icons/md";
import { IoIosTimer } from "react-icons/io";
import { FaAngleRight, FaRegHeart } from "react-icons/fa6";
//import { Salon } from "../../data/salonData";
import salonImg3 from "../../assets/brand/brandGallary/img3.png"; // Default Image
import offerIcon from "../../assets/salon/offers_icon.png";
import { getDistance } from "geolib";
import { addRecentlyViewed } from "../../store/slices/salonSlice"
interface SalonCardProps {
  isSpec?: boolean;
  salonData: Salon[];
  className?: string;
  heroClass?: string;
  showViewMore?: boolean;
}

// Ensure your 'Salon' interface matches the expected structure
interface Salon {
  shop_id: string;
  shop_name: string;
  profile_image_url?: string[];
  rating: number;
  isOpen: boolean;
  location?: string;
  distance: string | null;
  salonType: string;
  holiday: string;
  address: string;
  offer_message: string;
  review_count: number;
  average_rating: number;
  customer_category: string[];
  shop_weekly_timings: { [key: string]: string };
  locations?: {
    maps_link: string;
    city: string;
    state: string;
    address: string;
    pincode: string;
  };
}

const SalonCard: React.FC<SalonCardProps> = ({
  isSpec = true,
  salonData,
  className = "",
  heroClass = "",
  showViewMore,
}) => {
  const [visibleCount, setVisibleCount] = useState(3);
  const [userLocation, setUserLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [sortedSalons, setSortedSalons] = useState<Salon[]>(salonData);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const salonsPerPage = 3;
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error fetching location:", error);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (userLocation) {
      const salonsWithDistance = salonData.map((salon) => {
        let distance = null;
        if (salon.locations?.maps_link) {
          const match = salon.locations.maps_link.match(
            /@(-?\d+\.\d+),(-?\d+\.\d+)/
          );
          if (match) {
            const [_, lat, lng] = match;
            distance = calculateDistance(parseFloat(lat), parseFloat(lng));
          }
        }
        return { ...salon, distance };
      });

      // Sort salons by distance
      salonsWithDistance.sort((a, b) => {
        return parseFloat(a.distance || "0") - parseFloat(b.distance || "0");
      });

      setSortedSalons(salonsWithDistance);
    }
  }, [userLocation, salonData]);

  const addToRecentlyViewed = useCallback((salon: Salon) => {
    try {
      const existing = localStorage.getItem("recentlyViewedSalons");
      let salons: Salon[] = existing ? JSON.parse(existing) : [];
      salons = salons.filter((s: Salon) => s.shop_id !== salon.shop_id);
      salons.unshift(salon);
      if (salons.length > 3) {
        salons = salons.slice(0, 3);
      }
      localStorage.setItem("recentlyViewedSalons", JSON.stringify(salons));
    } catch (error) {
      console.error("Error adding to recently viewed salons:", error);
    }
  }, []);

  const navigate = useNavigate();

  const handlePagination = (direction: "next" | "prev") => {
    if (direction === "next") {
      setCurrentPage((prev) => Math.min(prev + 1, totalPages()));
    } else {
      setCurrentPage((prev) => Math.max(prev - 1, 1));
    }
  };

  const totalPages = () => {
    return Math.ceil(salonData.length / salonsPerPage);
  };

  const handleViewMore = () => {
      navigate("/salon-list-view");
  };

  const calculateDistance = (lat1: number, lng1: number) => {
    if (!userLocation) return null;
    const distance = getDistance(
      { latitude: userLocation.lat, longitude: userLocation.lng },
      { latitude: lat1, longitude: lng1 }
    );
    return (distance / 1000).toFixed(2); // Distance in kilometers
  };

  const todayshoptimings = (data: { [key: string]: string }) => {
    const today = new Date().toLocaleDateString("en-US", { weekday: "long" });

    const normalizedTimings: { [key: string]: string } = {};
    Object.keys(data).forEach((day) => {
      const normalizedDay =
        day.charAt(0).toUpperCase() + day.slice(1).toLowerCase();
      normalizedTimings[normalizedDay] = data[day];
    });

    // Get today's timings
    const todayTimings = normalizedTimings[today] || "Closed";
    return todayTimings;
  };

  const getHoliday = (data: Record<string, string>) => {
    const today = new Date().toLocaleDateString("en-US", { weekday: "long" });

    return (
      data[today]?.toLowerCase() === "closed" ||
      data[today]?.toLowerCase() === "holiday"
    );
  };

  const getHolidays = (data: Record<string, string>) => {
    const holidays = Object.keys(data).filter(
      (day) => data[day] === "Holiday" || data[day] === "Closed"
    );
    const holidayStrings = holidays.map(
      (day) => `${day.charAt(0).toUpperCase() + day.slice(1)} Closed`
    );

    return holidayStrings.length > 0
      ? holidayStrings.join(", ")
      : "No holidays";
  };

  const startIndex = (currentPage - 1) * salonsPerPage;
  const endIndex = startIndex + salonsPerPage;
  const salonsToDisplay = sortedSalons.slice(startIndex, endIndex);
  return (
    <div className={` `}>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {salonsToDisplay.map((salon) => {
          
          return (
            <main className="relative" key={salon.shop_id}>
              <div
                className="flex flex-col items-center md:items-start justify-center shadow-sm hover:shadow-md transition-all"
                style={{
                  border: "1px solid var(--Light-Grey-L2, #EBEBEB)",
                  borderRadius: "16px",
                }}
              >
                <div className="relative w-full h-[153px] sm:h-[183px] lg:h-[251px]">
                  {salon.profile_image_url &&
                  salon.profile_image_url.length > 0 ? (
                    <img
                      src={salon.profile_image_url[0]}
                      alt="Salon"
                      className="w-full rounded-t-xl h-[150px] lg:h-[232px] object-cover"
                      style={{ objectFit: "cover" }} // Ensures the image covers the entire area
                    />
                  ) : (
                    <img
                      src={salonImg3}
                      alt="Salon"
                      className="w-full rounded-t-xl h-[150px] lg:h-[232px] object-cover"
                      style={{ objectFit: "cover" }} // Ensures the image covers the entire area
                    />
                  )}

                  {/* <div className="absolute top-7 right-6 bg-white flex items-center justify-center w-8 h-8 rounded-full">
                  <FaRegHeart className="text-lg" />
                </div> */}
                </div>

                <div className="p-2 md:p-4 w-full space-y-2">
                  <div className="flex items-center relative w-full">
                    <h1 className="text-sm md:text-base lg:text-xl font-semibold text-gray-800 truncate">
                      {salon.shop_name}
                    </h1>
                    <div className="flex flex-1"></div>
                    {salon.average_rating >0 && (

                 
                    <div className="flex items-center gap-x-1 lg:gap-2 text-xs md:text-base">
                      <p className="font-semibold">{salon.average_rating}</p>
                      <FaStar className="text-[8px] text-primary md:text-base" />
                    </div>   
                  )}
                    {/* {salon.shop_weekly_timings ? (
                      <p
                        className={`absolute -bottom-5 right-0 text-xs lg:text-sm font-medium ${
                          !getHoliday(salon.shop_weekly_timings)
                            ? "text-green"
                            : "text-red"
                        }`}
                      >
                        {!getHoliday(salon.shop_weekly_timings)
                          ? "Open"
                          : "Closed"}
                      </p>
                    ) : (
                      <p
                        className={`absolute -bottom-5 right-0 text-xs lg:text-sm font-medium text-red`}
                      >
                        Closed
                      </p>
                    )} */}
                  </div>

                  <div className="flex items-center pt-2 lg:pt-2.5 text-DARKEST_GREY_L1">
                    <div className="flex items-center gap-1">
                      
                      {salon.address !== "Address not available" && (
                        <><MdOutlineLocationOn className="text-xs md:text-base" /><h4 className="text-xs lg:text-sm xl:text-base font-medium">
                          {salon.address}
                        </h4></>
                      )}
                    </div>
                    <div className="flex flex-1"></div>
                    {salon?.distance && (
                      <p className="italic text-[10px] md:text-xs">
                        {salon?.distance} km away
                      </p>
                    )}
                  </div>

                  <div className="flex flex-col lg:flex-row lg:items-center gap-y-2 lg:gap-5">
                    <div className="flex items-center gap-1">
                      <IoIosTimer className="text-xs md:text-base" />
                      {salon.shop_weekly_timings ? (
                        <p className="text-xs md:text-sm xl:text-base">
                          {todayshoptimings(salon.shop_weekly_timings)}
                        </p>
                      ) : (
                        <p className="text-xs md:text-sm xl:text-base">
                          10:00-8:00
                        </p>
                      )}
                    </div>
                    <div className="flex items-center gap-1">
                      <FaRegCalendarAlt className="text-xs md:text-base" />
                      {salon.shop_weekly_timings ? (
                        <p className="text-xs md:text-sm xl:text-base">
                          {getHolidays(salon.shop_weekly_timings)}
                        </p>
                      ) : (
                        <p className="text-xs md:text-sm xl:text-base">
                          no holiday
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center justify-center text-DARKEST_GREY_L1 md:pt-2">
                    {salon?.customer_category.map((it) => {
                      return (
                        <div className="bg-DARKEST_GREY_L4 border  border-DARKEST_GREY_L3 w-max px-2 lg:px-3 md:py-1 rounded-full text-center mr-2">
                          <button className="text-[10px] md:text-sm font-medium">
                            {it}
                          </button>
                        </div>
                      );
                    })}
                    <div className="flex flex-1"></div>
                    <Link
                      to={`/famous/hyderabad/salonProfile?shopId=${salon.shop_id}`}
                      onClick={() => addToRecentlyViewed(salon)}
                    >
                      <div className="flex items-center gap-1 lg:gap-2">
                        <p className="text-[10px] md:text-sm text-texthero">
                          View More
                        </p>
                        <FaAngleRight className="text-[8px] md:text-sm lg:text-base font-light text-DARKEST_GREY_L1" />
                      </div>
                    </Link>
                  </div>
                  {salon?.offer_message && (
                    <div className="relative border-t border-dotted border-DARKEST_GREY_L3 w-full mt-2">
                      {/* Offer Section */}
                      <div className="flex items-center justify-start gap-2 px-3 py-1">
                        <img
                          src={offerIcon}
                          alt="Offer Icon"
                          className="w-[20px] h-[20px]"
                        />
                        <p
                          className="text-[#0042A6] font-medium text-sm md:text-base leading-[20px]"
                          style={{ letterSpacing: "0.1px" }}
                        >
                          {salon?.offer_message}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </main>
          );
        })}
      </div>

      {showViewMore && sortedSalons.length > salonsPerPage && (
        <div className="flex justify-center mt-4">
          <div className="flex items-center gap-4">
            <button
              onClick={() => handlePagination("prev")}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-gray-300 rounded-md"
            >
              &lt; Previous
            </button>
            <span>
              {currentPage} of {totalPages()}
            </span>
            <button
              onClick={() => handlePagination("next")}
              disabled={currentPage === totalPages()}
              className="px-4 py-2 bg-gray-300 rounded-md"
            >
              Next &gt;
            </button>
          </div>
        </div>
      )}
      {!showViewMore && visibleCount < salonData.length && (
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-hero text-white rounded-md"
            onClick={handleViewMore}
          >
            View More
          </button>
        </div>
      )}
    </div>
  );
};

export default SalonCard;
