import React, { useCallback, useEffect, useState } from "react";
import SalonCard from "../Ui/SalonCard";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { recommendedsalons } from "../../store/slices/salonSlice";
import { RootState } from "../../store/store";

const RecommendedSalons: React.FC = () => {
  const salonData = useSelector(
    (state: RootState) => state.salon?.recommendedsalons
  );
  return (
    <React.Fragment>
      {salonData?.length > 0 && (
        <>
          <div className="flex items-center md:pt-5 lg:pb-8">
            <h1 className="font-medium max-w-[1200px] lg:font-normal text-xl md:text-2xl lg:text-4xl xl:text-[45px] text-texthero text-left">
              Recommended Salons
            </h1>
            <div className="flex flex-1"></div>
            <div className="flex items-center justify-end gap-1 lg:gap-2">
              {/* <p className="text-sm lg:text-base text-texthero">View All</p>   */}
              {/* <FaAngleRight className="text-sm lg:text-base font-light text-DARKEST_GREY_L1" /> */}
            </div>
          </div>
          <div className="w-full overflow-scroll hideScrollBar lg : flex items-center xl:m-auto xl:justify-center gap-5 pt-5 md:pt-5 lg:pt-0">
            <SalonCard
              salonData={salonData}
              className="gradient -border h-full w-[15rem] md:w-[355px] lg:w-[370px] xl:w-[400px]"
            />
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default RecommendedSalons;
