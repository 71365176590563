
import { combineReducers } from '@reduxjs/toolkit';
// Import your feature-specific reducers
import salonReducer from './slices/salonSlice';
import bookingReducer from './slices/bookingSlice'
import loginReducer from './slices/authSlice';
import userReducer from './slices/userCartSlice';
import salonDetailsReducer from './slices/salonDetailsSlice';
import salonServicesReducer from './slices/salonServicesSlice';
import salonOffersReducer from './slices/salonOffersSlice';
import salonPackagesReducer from './slices/salonPackagesSlice';
import salonReviewsReducer from './slices/salonReviewSlice';
import salonProfessionalReducer from './slices/salonProfessionalSlice'
import userAllCustomerDetailsReducer from './slices/userAllCustomerDetailsSlice';
import slotsReducer from './slices/salonSlotsSlice'
import customReducer from './slices/customSlice';
import brandReducer from "./slices/brandSlice";
import openPackageModelReducer from './slices/openPackageModelSlice';
import openServiceModelReducer from './slices/openServiceModelSlice';
import walletReducer from "./slices/userWalletSlice";
import couponReducer from "./slices/shopCouponSlice"
// Add other reducers here as needed

const rootReducer = combineReducers({
  salon: salonReducer,
  booking: bookingReducer,
  login:loginReducer,
  userCart:userReducer,
  brand: brandReducer,
  //salon profile Slices 
  salonDetails:salonDetailsReducer,
  salonServices:salonServicesReducer,
  salonPackages:salonPackagesReducer,
  salonOffers:salonOffersReducer,
  salonReviews:salonReviewsReducer,
  salonProfessionals:salonProfessionalReducer,
  userAllCustomerDetails:userAllCustomerDetailsReducer,
  salonSlots:slotsReducer,
  openPackageModel: openPackageModelReducer,
  openServiceModel: openServiceModelReducer,
  custom:customReducer,
  wallet:walletReducer,
  coupon: couponReducer

  // Add other reducers here
});

export default rootReducer;
