
import { createSlice, PayloadAction } from '@reduxjs/toolkit';




interface BookingDetails {
  booking_id: string;
  shop_id: string;
  professional_id: string;
  user_id: string;
  booking_customer_detail_id: string;
  slot_timings: string[];
  appointment_date: string;
  cost: number;
  status: string;
  services_ids: number[];
  packages_ids: number[];
 
}

interface IBookingState {
  selectedProfessional: string | number;
  bookingState:string,
  isBookingFlow:boolean,
  selectedProfessionId?:number,
  selectedDate:string,
  selectedTime:string[],
  numberOfSlots:number,
  slotError?:string,
  customerId:number|null,
  bookingDetails:BookingDetails |null
}



const initialState: IBookingState = {
  bookingState: '',
  isBookingFlow: false,
  selectedProfessionId: 0,
  selectedDate: '',
  selectedTime: [],
  numberOfSlots: 0,
  slotError: "",
  customerId: null,
  bookingDetails: null,
  selectedProfessional: "",
};

 

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setBookingState(state, action: PayloadAction<string>) {
      state.bookingState = action.payload;
    },
    setBookingFlow (state,action:PayloadAction<boolean>) {
      state.isBookingFlow = action.payload
    },
    setProfessionId(state,action:PayloadAction<number>) {
      state.selectedProfessionId=action.payload
    },
    setDateState(state,action:PayloadAction<string>) {
      state.selectedDate=action.payload
    },
    setTimeState(state,action:PayloadAction<string[]>) {
      state.selectedTime=action.payload
    },
    setNumberOfSlotsState(state,action:PayloadAction<number>) {
      state.numberOfSlots=action.payload
    },
   
    setSlotErrorState(state,action:PayloadAction<string>) {
      state.slotError=action.payload
    },
   
  setCustomerIdState(state,action:PayloadAction<number>) {
      state.customerId=action.payload
    },
    setBookingDetailsState(state,action:PayloadAction<BookingDetails>){
      state.bookingDetails=action.payload
    },
    
    setProfessionalState(state,action:PayloadAction<string>){
      state.selectedProfessional=action.payload
    }
  },
});

export const { setBookingState ,setBookingFlow,setProfessionId,setDateState,setTimeState,setNumberOfSlotsState,setSlotErrorState,setCustomerIdState,setBookingDetailsState, setProfessionalState} = bookingSlice.actions;
export default bookingSlice.reducer;
