import img1 from '../assets/homeFeatures/growTog/aic.png'
import img2 from '../assets/homeFeatures/growTog/nasscom.png';
import img3 from '../assets/homeFeatures/growTog/wadhwani.png';

// Define the type for each item in the growTogData array
interface GrowTogItem {
    id: number;
    imgUrl: string;
    title: string;
    description: string;
}

export const growTogData: GrowTogItem[] = [
    {
        id: 1,
        imgUrl: img1,
        title: 'AIC Mahindra',
        description: 'We are currently incubated at AIC Mahindra, specifically designed to help early stage start-ups',
    },
    {
        id: 2,
        imgUrl: img2,
        title: 'NASSCOM for Startups',
        description: 'We are a part of the NASSCOM Tech.WE program, which supports tech startups with required resources',
    },
    {
        id: 3,
        imgUrl: img3,
        title: 'DECODE 2.0',
        description: 'Pre-incubated with Wadhwani Foundation in collaboration with Innovation Mission Punjab',
    },
];
