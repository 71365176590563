import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { AppDispatch } from "../store/store";
import {
  fetchReviewsStart,
  fetchReviewsSuccess,
  fetchReviewsFailure,
  setUserisReviewedState,
} from "../store/slices/salonReviewSlice";
import { APOLLO_CLIENT_URI } from "../config/config";

const client = new ApolloClient({
  uri: `${APOLLO_CLIENT_URI}graphql`,
  cache: new InMemoryCache(),
});

const GET_SHOP_REVIEWS_QUERY = gql`
  query GetShopReviews($shopId: ID!, $rating: [Int]) {
    getShopReviews(shop_id: $shopId, rating: $rating) {
      ratings {
        rating
        review_tags
        review
        services
        fullname
        user_name
        created_at
      }
      averageRating
      totalRatings
      ratingCount {
        rating5
        rating4
        rating3
        rating2
        rating1
      }
    }
  }
`;

const GET_CHECK_IS_REVIEW_DONE = gql`
  query GetCheckIsReviewDone($bookingId: ID!, $userId: ID!) {
    getCheckIsReviewDone(booking_id: $bookingId, user_id: $userId) {
      isReviewDone
      reviewDetails {
        rating_id
        shop_id
        user_id
        rating
        review
        booking_id
        review_tags
        created_at
      }
    }
  }
`;

export const fetchShopReviews = async (
  dispatch: AppDispatch,
  shopId: string,
  rating: number[]
): Promise<void> => {
  dispatch(fetchReviewsStart());

  try {
    console.log(shopId, rating);
    const { data } = await client.query({
      query: GET_SHOP_REVIEWS_QUERY,
      variables: { shopId, rating },
    });
    dispatch(fetchReviewsSuccess(data.getShopReviews));
  } catch (error) {
    if (error instanceof Error) {
      dispatch(fetchReviewsFailure(error.message));
    } else {
      dispatch(fetchReviewsFailure("An unknown error occurred"));
    }
  }
};

export const fetchUserRatingStatus = async (
  dispatch: AppDispatch,
  bookingId: string,
  userId: string
): Promise<void> => {
  try {
    const { data } = await client.query({
      query: GET_CHECK_IS_REVIEW_DONE,
      variables: { bookingId, userId },
    });

    dispatch(setUserisReviewedState(data.getCheckIsReviewDone));
  } catch (error) {
    dispatch(fetchReviewsFailure("An unknown error occurred"));
  }
};
