import React from 'react';
import NavBar from '../components/Layout/NavBar';
import NavBarHome from '../components/Layout/NavBarHome';
import Section1Carousel from '../components/HomeLanding/Section1Carousel';
import TopSalons from '../components/HomeLanding/TopSalons';
import WhyStylin from '../components/HomeLanding/WhyStylin';
import StylinForBusiness from '../components/HomeLanding/StylinForBusiness';
import WhyTrustStylin from '../components/HomeLanding/WhyTrustStylin';
import GrowTog from '../components/HomeLanding/GrowTogether';
import Footer from '../components/Layout/Footer';
import BusinessForm from '../components/HomeLanding/ForBusinessForm';
import RecentlyViewedSalons from '../components/HomeLanding/RecentlyViewedsalon';
import RecommendedSalons from '../components/HomeLanding/RecommendedSalons';
import NewtostylinSalons from '../components/HomeLanding/NewtostylinSalons';
const HomeLanding:React.FC  =()=>{
    return (
        <main className="">
            <NavBarHome/>
        <section className="">
            <Section1Carousel/>
            <RecentlyViewedSalons/>
            <div className="pt-20 h-full mx-5 md:mx-7 lg:mx-16 xl:mx-24 md:pt-20  " >
               <RecommendedSalons/>
            </div>
            <div className="pt-20 h-full mx-5 md:mx-7 lg:mx-16 xl:mx-24 md:pt-20  " >
               <NewtostylinSalons/>
            </div>
            <div className="pt-20 h-full mx-5 md:mx-7 lg:mx-16 xl:mx-24 md:pt-20  " >
                <TopSalons  />
            </div>
            <div className="pt-[72px] h-full mx-5 md:mx-7 lg:mx-16 xl:mx-24 md:pt-32  lg:pt-44  ">
                <WhyStylin />
            </div>
             <div className="pt-[72px] h-full  md:pt-32  lg:pt-44  ">
                <StylinForBusiness />
            </div>
            <div className=" pt-[72px] md:pt-28 lg:pt-48   md:pb-20 overflow-x-hidden ">
                <WhyTrustStylin />
            </div>
            <div className="pt-[32px] h-full mx-5 md:mx-7 lg:mx-16 xl:mx-24   ">
                <GrowTog/>
            </div>
            <div className="pt-[32px] h-full mx-5 md:mx-7 lg:mx-16 xl:mx-24   ">
            <BusinessForm/>
            </div>

            <div className="pt-20">
                <Footer />
          </div>
        </section>
        </main>
    )
}

export default HomeLanding;