import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Customer } from '../../types/bookingFlowTypes';


interface CustomerState {
  user_id?: string;
  customers: Customer[];
  newCustomer:Customer[]
  loading: boolean;
  error: string | null;
}

const initialState: CustomerState = {
  user_id: '',
  customers: [],
  newCustomer:[],
  loading: false,
  error: null,
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    fetchUserDetailsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchUserDetailsSuccess(state, action: PayloadAction< Customer[]>) {
      // state.user_id = action.payload.user_id;
      state.customers = action.payload;
      state.loading = false;
    },
    fetchUserDetailsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    addNewUsersState(state,action:PayloadAction<Customer[]>) {
      state.newCustomer = action.payload
    }
  },
});

export const { fetchUserDetailsStart, fetchUserDetailsSuccess, fetchUserDetailsFailure,addNewUsersState } = customerSlice.actions;
export default customerSlice.reducer;

 
