import { salonInfoData } from "../data/salonInfoData";
import { initializeSalonPackages } from "../data/salonPackageData";
// import { salonReviewsData } from "../data/salonReviewsData";
import { initializeSalonServices } from "../data/salonServiceData";

// import { salonServiceData } from "../data/salonServiceData";
import { ExtPackage, ISalonInfo, IsalonPackages, Package, SubCategory } from "../types/salonServicesTypes";
//initilize the Variables 
let fetchedSalonCategories:Array<string>;
let fetchedSalonServices:Array<any>;
let fetchedSalonPackages:Package[];
// let fetchedSalonReviews:ISalonReviewCard[];
let fetchedSalonInfo:ISalonInfo[];



//fetch the values from Respective Apis
async function fetchSalonCategories ():Promise<void> {
    let salonCatelogs  = ['Recommended','Hair Care','Hair Removal','Massage','Facial Care','Hair Removal','Massage','Facial Care']
    fetchedSalonCategories=salonCatelogs

}
async function fetchSalonServices():Promise<void> {
    let salonServices  = await initializeSalonServices () ;
    if(salonServices){




        fetchedSalonServices=salonServices;
    }
    else {fetchedSalonServices=[]}
}

async function fetchSalonPackages():Promise<void>{
    let salonPackages =await initializeSalonPackages()
    if(salonPackages){
        // fetchedSalonPackages=salonPackages;
        }
        else {fetchedSalonPackages=[]}
}
 
async function fetchSalonInfo():Promise<void>{
    let salonInfo:ISalonInfo[]= salonInfoData;
    fetchedSalonInfo = salonInfo;
}

//invoking Function 

function getSalonCategories():Array<string> {
    fetchSalonCategories()
    return fetchedSalonCategories;
}
function getSalonServices () {
    fetchSalonServices();
    return fetchedSalonServices;
}
function getSalonPackages () {
    fetchSalonPackages();
    return fetchedSalonPackages;
}
 
function getSalonInfo () {
    fetchSalonInfo();
    return fetchedSalonInfo;
}

export {getSalonCategories,getSalonServices,getSalonPackages,getSalonInfo}