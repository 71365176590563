// openPackageModelSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OpenServiceModelState {
  isOpen: boolean;
  isRecommended: boolean;
  addedserviceId: string;
  openviewdetails: string | null;
}

const initialState: OpenServiceModelState = {
  isOpen: false,
  isRecommended: false,
  addedserviceId: "",
  openviewdetails: null
};

const openServiceModelSlice = createSlice({
  name: 'openServiceModel',
  initialState,
  reducers: {
    openServiceModel: (state) => {
      state.isOpen = true;
    },
    closeServiceModel: (state) => {
      state.isOpen = false;
    },
    recomendeServiceModel: (state) => {
        state.isRecommended = true;
    },
    closeRecommendedServiceModel: (state) => {
        state.isRecommended = false;
    },
    setAddeserviceId2: (state,action: PayloadAction<string>) => {
      state.addedserviceId = action.payload;
    },
    showViewDetails2: (state,action:PayloadAction<string>) => {
      state.openviewdetails = action.payload;
    },
    closeViewDetails2: (state) => {
      state.openviewdetails = null;
    }
  },
});

export const {showViewDetails2,closeViewDetails2, openServiceModel, closeServiceModel,recomendeServiceModel,setAddeserviceId2,closeRecommendedServiceModel } = openServiceModelSlice.actions;
export default openServiceModelSlice.reducer;
