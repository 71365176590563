import React from 'react';
import img1 from '../../assets/booking/error.png'
 
import BookingStatusCard from '../Ui/BookingStatusCard';
const BookingError : React.FC = ()=>{

        const btnActions : Array<{
            id:number,
            btnName:string,
            btnAction:()=>void,
            custClassName?:string
        }> = [
           
            {
                id:1,
                btnName:'Home',
                btnAction:()=>{},
                custClassName:'w-40 bg-texthero text-white'
            }
        ]

        const propValue = {
            title:"Oops!",
            description:" Something went wrong. Visit the home page to  restart your journey.",
            btnActions:btnActions,
            imgUrl:img1
        }


    return (
        <section className=" ">
            
            <BookingStatusCard
            statusObj={propValue}
            />
     
        </section>
    )
}

export default BookingError;