export     const loginInput:Array<{
    id:number,
    title:string,
    type:string,
    name:string
}>  = [
    {
        id:1,
        title:'Enter your first Name',
        type:'text',
        name:'name'
    },
    {
        id:2,
        title:'Select your Gender',
        type:'dropDown',
        name:'gender'

    }, 
    {
        id:3,
        title:'Date Of Birth',
        type:'date',
        name:'dob'

    }, 
    {
        id:4,
        title:'Phone Number',
        type:'number',
        name:'mobile'
    }, 
    {
        id:5,
        title:'Email Id',
        type:'email',
        name:'email'
    }, 
    {
        id:6,
        title:'Referrel code',
        type:'text',
        name:'referrelcode'
    }, 
   
   

]