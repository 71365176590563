import React from "react";
import { MdOutlineEmail } from "react-icons/md";

 
const ContactCard: React.FC = ( ) => {

    const cardData = [{
        id: 1,
        Logo: MdOutlineEmail,
        title: "Mail us",
        des: "Engage with Elite Experts",
        add: "admin@stylin.in",
    }]
    return (
      <div className="flex flex-col lg:flex-row justify-center items-center gap-12 lg:gap-52 w-full">
        {cardData.map((item, key) => (
          <div className="w-60   bg-white drop-shadow-xl space-y-2 p-5">
            <div className="text-4xl  w-full flex items-center justify-center ">
              <item.Logo />
            </div>
            <h2 className="text-center font-lexend font-semibold lg:text-lg">
              {item.title}
            </h2>
            <p className="text-center font-medium text-[#615e5e]">
              {item.des}
            </p>
            <p className="text-center font-semibold font-lexend ">
              {item.add}
            </p>
          </div>
        ))}
      </div>
    );
  };

export default ContactCard;
