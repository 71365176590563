import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { APOLLO_CLIENT_URI } from "../config/config";
const client = new ApolloClient({
  uri: `${APOLLO_CLIENT_URI}graphql`,
  cache: new InMemoryCache(),
});

export const CREATE_COUPON = gql`
  mutation CreateCoupon(
    $code: String!
    $discountType: String!
    $discountAmount: Float!
    $validity: String!
    $maxUsageCount: Int!
    $minOrderValue: Float
  ) {
    createCoupon(
      code: $code
      discountType: $discountType
      discountAmount: $discountAmount
      validity: $validity
      maxUsageCount: $maxUsageCount
      minOrderValue: $minOrderValue
    ) {
      id
      code
      discountType
      discountAmount
      validity
      maxUsageCount
      minOrderValue
      isActive
    }
  }
`;

export const VALIDATE_COUPON = gql`
  mutation ValidateCoupon($code: String!, $orderAmount: Float!) {
    validateCoupon(code: $code, orderAmount: $orderAmount) {
      isValid
      discountAmount
      finalAmount
      message
    }
  }
`;

export const FETCH_ALL_COUPONS = gql`
  query FetchAllCoupons {
    fetchAllCoupons {
      id
      code
      discountType
      discountAmount
      validity
      maxUsageCount
      minOrderValue
      usageCount
      isActive
    }
  }
`;
