import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTagsState } from '../../store/slices/customSlice';
import { RootState } from '../../store/store';

export const ReviewTags: React.FC = () => {
  const dispatch = useDispatch();
  const {isUserRated} = useSelector((state:RootState)=>state.salonReviews)

  const [tags, setTags] = useState([
    'Good Maintainance',
    'Stylist is Awesome',
    'Great Service',
    'Good Ambiance',
    'Will Recommend',
  ]);
  const [activeTags, setActiveTags] = useState<string[]>([]); // Initialize with an empty array

  const handleTagClick = (tag: string) => {
    if (activeTags.includes(tag)) {
      setActiveTags(activeTags.filter((t) => t !== tag));
    } else {
      setActiveTags([...activeTags, tag]);
    }
  };

  
  useEffect(()=>{
    if(isUserRated?.isReviewDone){
      setActiveTags(isUserRated.reviewDetails.review_tags);
    }
  },[isUserRated])

  useEffect(()=>{
    if(activeTags&&activeTags.length>0){
      dispatch(setActiveTagsState(activeTags))
    }
  },[activeTags])
  console.log('active tags', activeTags)

  return (
    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-3 md:gap-x-5 lg:gap-x-7 gap-y-4 justify-start  items-start  mt-5  "> 
      {tags.map((tag) => (
        <h2
          key={tag}
          className={`w-max ${
            activeTags&& activeTags.includes(tag.toLocaleLowerCase())
              ? 'bg-[#545452] text-white text-xs md:text-sm rounded-full px-4 py-1 active'
              : 'bg-[#b6b3b3] text-[11px] md:text-xs rounded-full px-4 py-1'
          } cursor-pointer m-auto`}
          onClick={() => !isUserRated?.isReviewDone && handleTagClick(tag.toLocaleLowerCase())}
        >
          {tag} {  activeTags&&activeTags.includes(tag) && <span onClick={(e) => {
            e.stopPropagation();
            handleTagClick(tag);
          }}>×</span>}
        </h2>
      ))}
    </div>
  );
};