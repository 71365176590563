import React, { useEffect, useState } from "react";
import axios from "axios";
import SalonCard from "../Ui/SalonCard";
import { FaFilter, FaSort } from "react-icons/fa";
import { MdOutlineLocationOn, MdSearch } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { BiCurrentLocation } from "react-icons/bi";
import noMatchImage from "../../assets/salon/no_match.png";
import { setallsalondata } from "../../store/slices/salonSlice";
import { useDispatch, useSelector  } from "react-redux";
import store, { AppDispatch, RootState } from "../../store/store";
const SalonListPage: React.FC = () => {
  const API_URL = "https://devapi.stylin.in/shop/getAllShops";
  const [salonData, setSalonData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [location, setLocation] = useState("");
  const [locationOptions, setLocationOptions] = useState<string[]>([]);
  const [filters, setFilters] = useState<string[]>(["All"]);
  const [sortBy, setSortBy] = useState<string>("");

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [showSortMenu, setShowSortMenu] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);

  // Update itemsPerPage based on screen size
  useEffect(() => {
    const updateItemsPerPage = () => {
      if (window.innerWidth >= 1024) { // For larger screens (desktop)
        setItemsPerPage(6); // Show 6 cards per page
      } else {
        setItemsPerPage(4); // Show 4 cards per page for mobile/tablet
      }
    };

    updateItemsPerPage(); // Initial check

    // Listen for window resize
    window.addEventListener("resize", updateItemsPerPage);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);

  // Fetch API Data
  useEffect(() => {
    const fetchSalons = async () => {
      try {
        const response = await axios.get(API_URL);
        if (response.status === 200) {
          console.log("fetchSalons",response.data.data); 
          setSalonData(response.data.data);
          dispatch(setallsalondata(response.data.data));
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching salons:", error);
        setError(true);
        setLoading(false);
      }
    };
    fetchSalons();
  }, []);

  const salondata = useSelector((state: RootState) => state.salon?.allsalondata);
  useEffect(()=>{
    setLocationOptions(salondata?.map((it) => it.locations?.city).filter((city): city is string => city !== undefined) || [])
  },[salondata]);
  
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Handle Location Filter
  const handleLocation = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLocation(e.target.value);
  };

  const handleRemoveAllFilters = () => {
    setFilters([]); // Clear all filters
    console.log("All filters removed");
  };


  // Detect Current Location
  const detectCurrentLocation = async () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser.");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
          );
          const detectedLocation =
            response.data.address?.city ||
            response.data.address?.state ||
            "Unknown Location";

          // Add detected location to the options if it doesn't already exist
          if (!locationOptions.includes(detectedLocation)) {
            setLocationOptions((prev) => [...prev, detectedLocation]);
          }

          // Set the detected location as selected
          setLocation(detectedLocation);
        } catch (error) {
          console.error("Error fetching location details:", error);
          alert("Unable to fetch location. Please try again.");
        }
      },
      (error) => {
        console.error("Error retrieving location:", error);
        alert("Unable to retrieve your location. Please check your permissions.");
      }
    );
  };

  const toggleFilter = (filter: string) => {
    setFilters((prevFilters) => {
      if (filter === "All") {
        return ["All"]; // Reset to "All" and deselect other filters
      }
  
      const updatedFilters = prevFilters.includes(filter)
        ? prevFilters.filter((f) => f !== filter) // Remove tag if already selected
        : [...prevFilters, filter]; // Add tag if not selected
  
      // Ensure "All" is removed if other tags are selected
      return updatedFilters.includes("All")
        ? updatedFilters.filter((f) => f !== "All")
        : updatedFilters;
    });
  };
  

  // Sort Data
  const sortSalons = (data: any[]) => {
    switch (sortBy) {
      case "Nearest":
        return data; // Replace with your nearest location logic
      case "High Rated":
        return [...data].sort((a, b) => b.rating - a.rating);
      case "Lowest Cost":
        return [...data].sort((a, b) => a.cost - b.cost);
      case "Highest Cost":
        return [...data].sort((a, b) => b.cost - a.cost);
      default:
        return data;
    }
  };


  const filteredSalons = sortSalons(
    salonData.filter((salon: any) => {
      console.log('Selected Filters:', filters);

      const matchesSearch =
        salon.shop_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (salon.locations && salon.locations?.city.toLowerCase().includes(searchTerm.toLowerCase()));
  
      const matchesLocation = location
        ? salon.locations?.city.toLowerCase() === location.toLowerCase()
        : true;
  
      const matchesTags =
        filters.includes("All")  
        ? true
        : filters.some((filter) => {
          switch (filter) {
            case "Men's Salon":
              return salon.customer_category?.includes("male");
            case "Women's Salon":
              return salon.customer_category?.includes("female");
            case "Unisex Salon":
              return (
                salon.customer_category?.includes("male") &&
                salon.customer_category?.includes("female")
              );
            case "Nail Centres":
              return salon.tags?.includes("nail"); // Adjust field as per your data
            case "Spas":
              return salon.tags?.includes("spa"); // Adjust field as per your data
            default:
              return false;
          }
        });
  
      return matchesSearch && matchesLocation && matchesTags;
    })
  );
  
  // Log the filtered results
  console.log('Filtered Salons:', filteredSalons);
  
  const sortedSalons = sortSalons(filteredSalons);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentSalons = sortedSalons.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedSalons.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  

  return (
    <div className="max-w-[1200px] mx-auto px-4 py-6 space-y-6">
      {/* Header */}
      <div className="flex flex-col md:flex-row gap-4 items-center">
        <div className="flex text-[#B2B2B2] gap-2 w-full h-[35px] md:w-[204px] md:h-[40px] items-center relative">
          <button
            onClick={detectCurrentLocation}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-700"
          >
            <BiCurrentLocation size={24} />
          </button>
          <select
            value={location}
            onChange={handleLocation}
            className="border rounded p-2 w-[320px] h-[48px] border-[#B2B2B2] shadow-sm"
          >
            <option value="">Location</option>
            {locationOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
            
        <div className="flex items-center gap-4 w-full p-2">
          {/* Search */}
          <div className="flex flex-1 items-center gap-2 h-[40px] md:h-[40px] border border-[#B2B2B2] rounded p-2 shadow-sm">
            <MdSearch className="text-[#B2B2B2]" />
            <input
              type="text"
              placeholder="Find services or salons here"
              value={searchTerm}
              onChange={handleSearch}
              className="w-full outline-none"
            />
          </div>

          {/* Filter and Sort Buttons */}
          <div className="relative">
            {/* Filter */}
            <button
              className="flex items-center gap-1 w-[40px] h-[40px] border border-[#B2B2B2] rounded p-3 shadow-sm"
              onClick={() => setShowFilterMenu((prev) => !prev)}
            >
              <FaFilter />
            </button>
            {showFilterMenu && (
              <div className="absolute top-full right-0 w-[126px] h-[240px] md:w-[144px] md:h-[325px] lg:w-[200px] lg:h-[325px] bg-white border-none shadow-md rounded z-10">
                {["Unisex Salon", "Men's Salon", "Women's Salon", "Open Now", "Deals"].map(
                  (filter) => (
                    <button
                      key={filter}
                      onClick={() => toggleFilter(filter)}
                      className={`block px-2 py-2 text-sm lg:text-base font-normal text-left w-[126px] h-[48px] lg:w-[200px] lg:h-[65px] ${
                        filters.includes(filter) ? "bg-DARKEST_GREY_L3" : "hover:bg-DARKEST_GREY_L2"
                      }`}
                    >
                      {filter}
                    </button>
                  )
                )}
              </div>
            )}
          </div>

          {/* Sort */}
          <div className="relative">
            <button
              className="flex items-center gap-1 w-[40px] h-[40px] border border-[#B2B2B2] rounded p-3 shadow-sm"
              onClick={() => setShowSortMenu((prev) => !prev)}
            >
              <FaSort /> 
            </button>
            {showSortMenu && (
              <div className="absolute top-full right-0 bg-white border-none w-[126px] h-[192px] md:w-[144px] md:h-[325px] lg:w-[200px] lg:h-[260px] shadow-md rounded p-2 z-10">
                {["Nearest", "High Rated", "Lowest Cost", "Highest Cost"].map((sortOption) => (
                  <button
                    key={sortOption}
                    onClick={() => {
                      setSortBy(sortOption);
                      setShowSortMenu(false);
                    }}
                    className={`block px-3 py-2 text-sm lg:text-base font-normal text-left w-[126px] h-[48px] lg:w-[200px] lg:h-[65px] ${
                      sortBy === sortOption ? "bg-DARKEST_GREY_L3" : "hover:bg-DARKEST_GREY_L2"
                    }`}
                  >
                    {sortOption}
                  </button>
                ))}
              </div>
            )}
          </div>  
        </div>
      </div>

      {/* Tags */}
      <div className="w-full overflow-x-auto"
           style={{
            scrollbarWidth: "none", /* Firefox */
            msOverflowStyle: "none", /* Internet Explorer */
          }} 
      >
        <div className="flex gap-2 flex-nowrap"
             style={{
              scrollbarWidth: "none", /* Firefox */
            }} 
        >
          {["All", "Men's Salon", "Women's Salon", "Nail Centres", "Spas"].map(
            (tag) => (
              <button
                key={tag}
                onClick={() => toggleFilter(tag)}
                className={`px-4 py-1 text-[14px] font-medium border-none rounded-full whitespace-nowrap ${
                  filters.includes(tag)
                    ? "bg-black text-white"
                    : "bg-[#EBEBEB] text-[#4F4F4F]"
                }`}
              >
                {tag}
              </button>
            )
          )}
        </div>
      </div>  

      {/* Content */}
      {loading ? (
        <p className="text-center text-gray-600">Loading...</p>
      ) : error ? (
        <p className="text-center text-red-600">Error fetching data</p>
      ) : filteredSalons.length > 0 ? (
        <>
          <SalonCard
            salonData={currentSalons}
            isSpec={false}
            showViewMore={true} // Disable "View More" functionality 
          />

          

          <div className="flex justify-center mt-4">
            <Link
              to="/salon-list-view"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              View More <IoIosArrowForward />
            </Link>
          </div>
        </>
      ) : (
        <div className="text-center flex flex-row items-center justify-center mt-8 space-x-8">
          <div className="w-[456px] h-[172px] gap-8 mt-8">
            <h2 className="text-[32px] font-semibold text-black mb-2">No Exact Matches</h2>
            <p className="text-[#4F4F4F] text-base font-medium mb-4">
              Try removing some filters or adjusting your search for your requirements.
            </p>
            <button
              onClick={handleRemoveAllFilters} // Replace with your handler function
              className="bg-black w-[198px] h-[40px] text-white px-4 py-2 rounded text-sm font-medium hover:bg-blue"
            >
              Remove All Filters
            </button>
          </div>  
          <img
            src={noMatchImage}
            alt="No results found"
            className="w-[264px] h-[264px] "
          />
  
        </div>

          )}
        </div>
  );
};

export default SalonListPage;


