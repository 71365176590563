import axios, { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import {
  setNoSlotsMessage,
  setOtherProfessionals,
  setSalonSlots,
} from "../store/slices/salonSlotsSlice";

const API_ENDPOINT = process.env.REACT_APP_API_URL;

export const fetchSalonSlots = async (
  dispatch: Dispatch,
  numberOfSlots: number,
  professional_id: number,
  slot_dates: string,
  shopId: string
): Promise<void> => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API_ENDPOINT}booking/getAvailableSlots?number_of_slots=${numberOfSlots}&professional_id=${professional_id}&date=${slot_dates}&shopId=${shopId}
 `,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "your_token_here",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
        },
      }
    );
    console.log("available slots with no slots ", response.data);
    const availableSlotsArray = response.data.availableSlotsArray;
    const noSlotsMessage = response.data.message;
    const otherProfessionals = response.data.otherAvailableProfessionals;

    if (availableSlotsArray && availableSlotsArray.length > 0) {
      dispatch(setSalonSlots(availableSlotsArray));
      dispatch(setNoSlotsMessage(""));
      dispatch(setOtherProfessionals([]));
    } else {
      dispatch(setSalonSlots([]));
      dispatch(setNoSlotsMessage(noSlotsMessage));
      dispatch(setOtherProfessionals(otherProfessionals));
    }
  } catch (error) {
    console.error("Error fetching salon slots:", error);
  }
};
