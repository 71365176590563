export const emojiData : Array<{
    gifSrc:string,
    altText:string,
    title:string
}> = [
 
        {
          gifSrc: "1f635/512", // Ensure this matches the expected URL format
          altText: "😵",
          title: "Dissatisfied",
        },
        {
          gifSrc: "1f641/512",
          altText: "🙁",
          title: "Unhappy",
        },
        {
          gifSrc: "1f610/512",
          altText: "😐",
          title: "Neutral",
        },
        {
          gifSrc: "1f642/512",
          altText: "🙂",
          title: "Happy",
        },
        {
          gifSrc: "1f600/512",
          altText: "😀",
          title: "Satisfied",
        },
        // Add more emoji data here if needed
    
]