import React, { useState } from "react";
import EmojiPicture from "../Svg/EmojiSvg"; 
import { emojiData } from "../../data/emojiData";
import InputPP from "../Common/InputPP";
import { useNavigate, useParams } from "react-router-dom";
import { extractShopIdFromUrl } from "../../utils/salonProfile";

const BookingFeedback: React.FC = () => {
  const [activeEmoji, setActiveEmoji] = useState<number | null>(null);
  const [textValue, setTextValue] = useState<string>("");

  const navigate = useNavigate();
  const salonId = extractShopIdFromUrl();

  const { shopName, city } = useParams<{ shopName: string; city: string }>();

  const handleEmojiClick = (index: number) => {
    setActiveEmoji(index); 
  };

  const handleSubmit = () => {
    if (!activeEmoji) return;
    setTimeout(() => {
      window.location.href = `/${shopName}/${city}/salonProfile?shopId=${salonId}`;
    }, 1000);
  };

  const handleSkip = () => {
    window.location.href = `/${shopName}/${city}/salonProfile?shopId=${salonId}`;
  };

  return (
    <section className="bg-white relative md:w-max rounded-xl md:rounded-2xl h-auto md:h-96 xl:h-[27rem] px-5 md:px-20 lg:px-28 xl:px-32 flex flex-col justify-between items-center py-6">
      <h3 className="w-72 md:w-max text-center md:text-start text-sm md:text-base font-medium text-texthero">
        Share your valuable feedback to help us improve your experience
      </h3>

      {/* Emoji Section */}
      <div className="flex items-center space-x-3 md:space-x-9 pt-4">
        {emojiData.map((emoji, index) => (
          <div
            className="flex flex-col items-center cursor-pointer"
            key={index}
            onClick={() => handleEmojiClick(index)} // Handle emoji selection
          >
            <EmojiPicture
              key={index}
              gifSrc={emoji.gifSrc}
              altText={emoji.altText}
              size={40}
              isActive={activeEmoji === index}
              onClick={() => handleEmojiClick(index)}
            />
            <h3 className="text-xs text-DARKEST_GREY_L2 text-center mt-2">
              {emoji.title}
            </h3>
          </div>
        ))}
      </div>

      {/* Text Input Section */}
      <div className="md:w-96 mt-4">
        <InputPP
          label="Text Input"
          type="text"
          placeholder="Share your feedback"
          istextArea={true}
          custClassName="mt-3"
          value={textValue}
          onChange={(value) => setTextValue(value)}
        />
      </div>

      {/* Buttons Section */}
      <div className="flex justify-between w-full mt-6 gap-4">
        {/* Skip Button */}
        <button
          onClick={handleSkip}
          className="px-6 py-2 border bg-hero bg-transparent text-white rounded-md text-sm font-semibold hover:bg-gray-100 hover:border-gray-500 hover:text-gray-900 transition-all duration-300"
        >
          Skip
        </button>

        {/* Submit Button */}
        <button
          onClick={handleSubmit}
          className={`px-6 py-2 border bg-hero text-white rounded-md text-sm font-semibold hover:bg-blue-600 hover:border-blue-600 
          } transition-all cursor-pointer duration-300`}
          disabled={!activeEmoji } 
        >
          Submit
        </button>
      </div>
    </section>
  );
};

export default BookingFeedback;
