// src/store/slices/salonSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SalonState {
  categories: string[];
  activeCategory: string;
  allsalondata: Salon[];
  recentlyViewed: Salon[];
  newtostylinsalons: Salon[];
  recommendedsalons: Salon[];
}
interface Salon {
  shop_id: string;
  shop_name: string;
  profile_image_url?: string[];
  rating: number;
  isOpen: boolean;
  location?: string;
  distance: string | null;
  salonType: string;
  holiday: string;
  address: string;
  offer_message: string;
  review_count: number;
  average_rating: number;
  customer_category: string[];
  shop_weekly_timings: { [key: string]: string };
  locations?: {
    maps_link: string;
    city: string;
    state: string;
    address: string;
    pincode: string;
  };
}

const initialState: SalonState = {
  categories: [],
  activeCategory: 'Recommended',
  allsalondata: [],
  recentlyViewed: [],
  newtostylinsalons: [],
  recommendedsalons: [],
};

const salonSlice = createSlice({
  name: 'salon',
  initialState,
  reducers: {
    setCategories(state, action: PayloadAction<string[]>) {
      state.categories = action.payload;
    },
    setActiveCategory(state, action: PayloadAction<string>) {
      state.activeCategory = action.payload;
    },
    setallsalondata(state,action: PayloadAction<Salon[]> ){
      state.allsalondata = action.payload;
    },
    addRecentlyViewed(state, action: PayloadAction<Salon>) {
      // Check if salon is already in the recently viewed list
      const exists = state.recentlyViewed.find(
        (salon) => salon.shop_id === action.payload.shop_id
      );
      if (!exists) {
        // Add the new salon to the start of the list
        state.recentlyViewed.unshift(action.payload);
        // Limit the list to the last 10 items
        if (state.recentlyViewed.length > 10) {
          state.recentlyViewed.pop();
        }
      }
    },
    newtostylinsalons(state,action: PayloadAction<Salon[]>){
      state.newtostylinsalons = action.payload;
    },
    recommendedsalons(state,action: PayloadAction<Salon[]>){
      state.recommendedsalons = action.payload;
    }
  },
});

export const { setCategories, setActiveCategory,setallsalondata , addRecentlyViewed,recommendedsalons,newtostylinsalons} = salonSlice.actions;
export default salonSlice.reducer;
