import React, { useState, useEffect } from "react";
import Carousel from "../Carousel/Carousel";
import SlotDateCard from "../Ui/SlotDateCard";
import SlotTimeCard from "../Ui/SlotTimeCard";
import OrderHeaderBar from "../Layout/OrderHeaderBar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { GetAllSlots } from "../../types/bookingFlowTypes";
import { getDateAndDay, getMonthAndYear } from "../../utils/slotSchedule";

const TimeSchedule: React.FC = () => {
  const monthAndYearList = getMonthAndYear();
  const dateAndDayList = getDateAndDay();

  const getSlots = useSelector(
    (state: RootState) => state.salonSlots.availableSlots
  );
  const noSlotsMessage = useSelector(
    (state: RootState) => state.salonSlots.noSlotsMessage
  );
  const otherProfessionals = useSelector(
    (state: RootState) => state.salonSlots.otherProfessionals
  );
  const selectedDate = useSelector(
    (state: RootState) => state.booking.selectedDate
  );

  const selectedProfessional = useSelector(
    (state: RootState) => state.booking.selectedProfessionId
  ); 

  const [fetchedSlots, setFetchedSlots] = useState<GetAllSlots[]>([]);
  const [updatedSlots, setUpdatedSlots] = useState<GetAllSlots[]>([]);
  const [isSlotsInTime, setIsSlotsInTime] = useState<boolean>(true);

  const currentDate = new Date();
  const formattedCurrentDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`; // Format to day/month/year
  
  const [year, month, day] = selectedDate
    .split("-")
    .map((x) => x.replace(/^0/, "")); 
  const formattedSelectedDate = `${day}/${month}/${year}`;

  
  useEffect(() => {
    const now = new Date();

    const parseTimeTo24HourFormat = (timeStr: {
      includes: (arg0: string) => any;
      replace: (
        arg0: string,
        arg1: string
      ) => {
        (): any;
        new (): any;
        replace: {
          (arg0: string, arg1: string): {
            (): any;
            new (): any;
            trim: {
              (): {
                (): any;
                new (): any;
                split: {
                  (arg0: string): {
                    (): any;
                    new (): any;
                    map: { (arg0: NumberConstructor): [any, any]; new (): any };
                  };
                  new (): any;
                };
              };
              new (): any;
            };
          };
          new (): any;
        };
      };
    }) => {
      const isPM = timeStr.includes("PM");
      let [hour, minute] = timeStr
        .replace("AM", "")
        .replace("PM", "")
        .trim()
        .split(":")
        .map(Number);

      if (isPM && hour < 12) hour += 12;
      if (!isPM && hour === 12) hour = 0;
      return [hour, minute];
    };

    const isSlotTimeValid = (slotTimeStr: any) => {
      const [slotHour, slotMinute] = parseTimeTo24HourFormat(slotTimeStr);
      const slotTime = new Date();
      slotTime.setHours(slotHour, slotMinute, 0, 0);
      return slotTime >= now;
    };

    const filterSlots = (slots: any[]) => {
      return slots.filter((slot) => isSlotTimeValid(slot.slot_time));
    };

    const filteredSlots = fetchedSlots.map((slot) => {
      const filteredMorningShift = filterSlots(slot.morning_shift);
      const filteredEveningShift = filterSlots(slot.evening_shift);

      return {
        ...slot,
        morning_shift: filteredMorningShift,
        evening_shift: filteredEveningShift,
      };
    });
 
    if (formattedCurrentDate === formattedSelectedDate) {
      setUpdatedSlots(filteredSlots);
  
      const hasAvailableSlots = filteredSlots.some(
        (slot) =>
          slot.morning_shift.length > 0 || slot.evening_shift.length > 0
      );
      setIsSlotsInTime(hasAvailableSlots);
    } else {
      setUpdatedSlots(fetchedSlots);
      setIsSlotsInTime(true);
    }
  }, [ fetchedSlots, formattedCurrentDate, formattedSelectedDate]);

  useEffect(() => {
    setFetchedSlots(getSlots);
  }, [getSlots]);

  return (
    <section className="  ">
      <OrderHeaderBar title="Select Schedule" />

      <div className="relative pt-3 lg:pt-5  px-3 lg:px-10 ">
        <div className="flex items-center gap-1 text-texthero lg:text-lg font-medium lg:pb-3">
          <h3 className="">{monthAndYearList.month}</h3>
          <h4 className="">{monthAndYearList.year}</h4>
        </div>

        <Carousel
          scrollVal={600}
          isCustCls={true}
          custClassName="top-4 right-0 "
        >
          {dateAndDayList.map((slot, key) => (
            <div
              className={` relative flex flex-col items-center justify-end  `}
              key={key}
            >
              <SlotDateCard isSlotsAvailable={isSlotsInTime} slotDates={slot} />
            </div>
          ))}
        </Carousel>
      </div>

      {selectedDate && !selectedProfessional && (
        <div className="pt-10 px-2 lg:px-10">
          <h2 className="text-DARKEST_GREY_L1 font-medium">
            Please select a professional first
          </h2>
        </div>
      )}
      {selectedProfessional && (
        <>
          {noSlotsMessage && (
            <div className="pt-10 px-2 lg:px-10">
              <h2 className="text-DARKEST_GREY_L1 font-medium">
                {noSlotsMessage}
              </h2>
              <div className="mt-5">
                <h3 className="text-DARKEST_GREY_L1">Other Professionals:</h3>
                <ul>
                  {otherProfessionals?.map((professional) => (
                    <li
                      key={professional.professional_id}
                      className="text-DARKEST_GREY_L2"
                    >
                      {professional.first_name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {updatedSlots.length > 0 && (
            <div className={`${selectedDate == "" && "hidden"}`}>
              {updatedSlots.map((slot, key) => (
                <div>
                  {slot.morning_shift.length > 0 && (
                    <div className="pt-12 px-2 lg:px-10 ">
                      <h2 className="text-DARKEST_GREY_L1 font-medium">
                        Morning Shift
                      </h2>
                      <div className="grid grid-cols-3 gap-3 lg:gap-0 lg:grid-cols-4">
                        <SlotTimeCard
                          key={key}
                          slots={slot.morning_shift ?? []}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <div>
                {updatedSlots.map((slot, key) => (
                  <>
                    {slot.evening_shift.length > 0 && (
                      <div className="pt-10 lg:pt-12 px-2 lg:px-10 ">
                        <h2 className="text-DARKEST_GREY_L1 font-medium">
                          Afternoon Shift
                        </h2>
                        <div className="grid grid-cols-3 gap-3 lg:gap-0 lg:grid-cols-4">
                          <SlotTimeCard
                            key={key}
                            slots={slot.evening_shift ?? []}
                          />
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default TimeSchedule;
