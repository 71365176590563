import React, { useState, useEffect, Suspense, lazy } from "react";
// import HeroImg from "../../assets/homeFeatures/section1hero.svg";
import tabHeroImg from "../../assets/homeFeatures/tabSection1hero.svg";
import mobHeroImg from "../../assets/homeFeatures/mobSection1hero.svg";
import { useInView } from "react-intersection-observer";
import img1 from "../../assets/homeFeatures/heroCarousel/img1.png";
import img2 from "../../assets/homeFeatures/heroCarousel/img2.png";
import img3 from "../../assets/homeFeatures/heroCarousel/img3.png";
import img4 from "../../assets/homeFeatures/heroCarousel/img4.png";
import img5 from "../../assets/homeFeatures/heroCarousel/img5.png";
// import InputPP from "../../utility/InputPP";
// import DropDownPP from "../../utility/DropDownPP";
import { CiLocationOn } from "react-icons/ci";
import { IoIosSearch } from "react-icons/io";
import InputPP from "../Common/InputPP";
import DropdownPP from "../Common/DropdownPP";
import InputPPHome from "../Common/InputPPHome";
import DropDownPPHome from "../Common/DropdownPPHome";
import { useDispatch, useSelector } from "react-redux";
import store, { AppDispatch, RootState } from "../../store/store";
import axios from "axios";
import { newtostylinsalons, recommendedsalons, setallsalondata } from "../../store/slices/salonSlice";

// const LazySection = lazy(() => import("../../utility/LazyLoad"));

interface Section1Props {
  onBookNowClick?: () => void;
}
interface dropVal {
  city: []
}

const Section1: React.FC<Section1Props> = ({ onBookNowClick }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const images: string[] = [img1, img2, img3, img4, img5];

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    const img = new Image();
    // img.src = HeroImg;
    img.onload = () => setImageLoaded(true);
  }, []);
  const salondata = useSelector((state: RootState) => state.salon?.allsalondata);
  const dropVal: string[] = salondata?.map((it) => it.locations?.city).filter((city): city is string => city !== undefined) || [];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const dispatch = useDispatch();
  const API = "https://devapi.stylin.in/shop/getAllShops";
  //const API = "http://localhost:3000/shop/getAllShops";
  const salonData = useSelector((state: RootState) => state.salon?.allsalondata);
  useEffect(() => {
    const fetchsalondata = async() => {
      try{
        const response = await axios.get(API);
        if(response.status === 200){
          //setSalonData(response.data.data);
          dispatch(setallsalondata(response.data.data));
          dispatch(recommendedsalons(response.data.recommendedSalons));
          dispatch(newtostylinsalons(response.data.latestSalons));
        }
        console.log(salonData);

      }catch(error){
        console.log(error);
      }
    }
    fetchsalondata();

  },[]);

  return (
    <div ref={ref}>
      {!inView ? (
        <Suspense fallback={<div className=" ">Loading...</div>}>
          {/* <LazySection /> */}
        </Suspense>
      ) : (
        <div className="carousel-home relative">
          {images.map((image, index) => (
            <div
              key={index}
              className={`carousel-image ${
                index === currentIndex ? "active" : ""
              } ${
                index === (currentIndex + images.length - 1) % images.length
                  ? "previous"
                  : ""
              }`}
              style={{ backgroundImage: `url(${image})` }}
            />
          ))}
          <div className="absolute z-40 flex flex-col items-center justify-center h-full w-full">
            <h1 className="text-center w-80 lg:w-full text-3xl lg:text-6xl xl:text-[75px] font-extralight text-white">
              Discover Premium Beauty <br className="hidden lg:block" />{" "}
              Services In Hyderabad
            </h1>
            <div className="flex flex-col items-center justify-center md:block w-full md:w-max mt-12 md:mt-0">
              <div className="bg-white md:hidden relative mb-5 px-16 py-3 flex items-center rounded-md ">
                <DropDownPPHome
                  className={` h-full  w-full  `}
                  labelClassName="hidden"
                  parentClassName="h-full flex  justify-center  pr-5  border-r border-DARKEST_GREY_L3 text-DARKEST_GREY_L3 items-center"
                  id="location"
                  //   name="location" // Ensure name attribute is set
                  required={false}
                  options={dropVal}
                  // value={formData[inputItem.title
                  // onChange={handleChange} // Handle dropdown changes
                />
              </div>
              <div className="bg-white relative flex items-center rounded-md lg:mt-14 py-1.5 lg:py-2.5 xl:py-3 lg:px-6">
                <DropDownPPHome
                  className={`  w-full   p-2 lg:p-3 `}
                  labelClassName="hidden"
                  parentClassName="h-full hidden lg:flex justify-center  pr-5  border-r border-DARKEST_GREY_L3 text-DARKEST_GREY_L3 items-center"
                  id="location"
                  //   name="location" // Ensure name attribute is set
                  required={false}
                  options={dropVal}
                  // value={formData[inputItem.title
                  // onChange={handleChange} // Handle dropdown changes
                />
                <InputPPHome
                  className={`text-sm md:text-base  rounded-md w-64  lg:w-96 p-1.5 lg:p-3  `}
                  labelClassName="hidden"
                  id="salon"
                  //   name="salon" // Ensure name attribute is set
                  required={false}
                  brClass="hidden"
                  type="text"
                  borderReq={false}
                  placeholder="Explore services, salons, professionals, etc."
                  // value={formData[inputItem.title] }
                  // changeFunction={handleChange} // Handle input changes
                />
                <button className="bg-texthero absolute right-2 md:relative font-medium cursor-pointer text-white p-2 lg:py-2.5 lg:px-8 lg:text-sm rounded-md">
                  <p className="hidden lg:block">Explore</p>
                  <IoIosSearch className="lg:hidden" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Section1;
