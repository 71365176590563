import React from "react";
 
import { FaPhoneAlt, FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import ContactCard from "../Ui/ContactCard";

// Define the interface for the card data structure
 

const Section2: React.FC = () => {
    

    return (
        <div className="pb-10 lg:pb-52 px-5 pt-10 lg:pt-0">
            <div className="text-center flex justify-center gap-2">
                <h2 className="text-lg lg:text-2xl font-semibold text-[#494646] font-lexend text-center">
                    Reach us for personalized support!
                </h2>
            </div>

            <div className="w-max m-auto pt-5 lg:pt-10">
                <a
                    href={`mailto:admin@stylin.in`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <ContactCard   />
                </a>
            </div>
        </div>
    );
};

export default Section2;
