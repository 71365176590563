import React from "react";

// Define the shape of the props
interface WhyStylinCardProps {
    data: Array<{
        id: number;
        imgUrl: string;
        title: string;
        des: string;
    }>;
}

// Component Definition
const WhyStylinCard: React.FC<WhyStylinCardProps> = ({ data }) => {
    return (
        <main className="md:grid lg:flex md:grid-cols-2 gap-10 lg:gap-4 space-y-2 md:space-y-0 h-full">
            {data.map((item) => (
                <div className="text-texthero flex flex-col items-center gapy gradient-border w-72 p-4" key={item.id}>
                    <img
                        src={item.imgUrl}
                        alt={item.title}
                        className="object-fill h-8 md:h-12 lg:h-16"
                    />
                    <div>
                        <h3 className="lg:text-xl font-medium py-2 md:py-4 lg:py-6 text-center">
                            {item.title}
                        </h3>
                        <p className="font-light text-[#4F4F4F] lg:block text-center text-xs md:text-base m-auto lg:pb-4 px-3 md:px-0 lg:px-2">
                            {item.des}
                        </p>
                    </div>
                </div>
            ))}
        </main>
    );
};

export default WhyStylinCard;
