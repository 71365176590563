import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserCartState {
  addedServices: Array<{
    addedService: string;
    price: number;
    discountAmount: number;
    time: string;
    serviceType?: string;
    cartType: string; // Updated to use specific types
    id: string;
  }>;
  addUpPrice: number;
  discount: number;
  total: number;
  totalDiscount: number;
  totalService: number;
  totaltime: string;
  cartAdd?: boolean;
  offerMargin?: number;
}

const initialState: UserCartState = {
  addedServices: [],
  addUpPrice: 0,
  discount: 0,
  total: 0,
  totalDiscount: 0,
  totalService: 0,
  totaltime: "",
  cartAdd: false,
};

const userCartSlice = createSlice({
  name: "userCart",
  initialState: initialState,
  reducers: {
    addService(
      state,
      action: PayloadAction<{
        addedService: string;
        price: number;
        time: string;
        serviceType: string;
        cartType: string;
        id: string;
        discountAmount: number;
      }>
    ) {
      state.addedServices.push(action.payload);
      state.addUpPrice += action.payload.price;
      state.totalDiscount += action.payload.discountAmount;
      state.total = state.addUpPrice - state.totalDiscount;
      state.totalService += 1;
      state.totaltime = +state.totaltime + +action.payload.time + " ";
    },
    
    removeService(
      state,
      action: PayloadAction<{
        id: string;
        cartType: string;
      }>
    ) {
      // Find the item to remove by both id and cartType
      const itemToRemove = state.addedServices.find(
        (service) => service.id === action.payload.id && service.cartType === action.payload.cartType
      );

      if (itemToRemove) {
        // Update the totals
        state.addUpPrice -= itemToRemove.price;
        state.totalDiscount -= itemToRemove.discountAmount;
        state.totalService -= 1;
        state.total = state.addUpPrice - state.totalDiscount;
        state.totaltime = +state.totaltime - +itemToRemove.time + " ";

        // Remove the item from the array
        state.addedServices = state.addedServices.filter(
          (service) => !(service.id === action.payload.id && service.cartType === action.payload.cartType)
        );
      }
    },

    updateService(
      state,
      action: PayloadAction<{
        addedService: string;
        price: number;
        time: string;
        serviceType: string;
        cartType: string;
        id: string;
        discountAmount: number;
      }>
    ) {
      const index = state.addedServices.findIndex(
        (service) => service.id === action.payload.id && service.cartType === action.payload.cartType
      );

      if (index !== -1) {
        const existingService = state.addedServices[index];

        // Adjust totals based on the difference with the new values
        state.addUpPrice += action.payload.price - existingService.price;
        state.totalDiscount += action.payload.discountAmount - existingService.discountAmount;
        state.total = state.addUpPrice - state.totalDiscount;
        state.totaltime = +state.totaltime - +existingService.time + +action.payload.time + " ";

        // Update the existing service with the new details
        state.addedServices[index] = action.payload;
      }
    },

    setCardAdd(state, action: PayloadAction<boolean>) {
      state.cartAdd = action.payload;
    },

  },
});

export const { addService, removeService, updateService, setCardAdd } = userCartSlice.actions;
export default userCartSlice.reducer;


