import React from 'react';
import NavBarHome from '../components/Layout/NavBarHome';
import Footer from '../components/Layout/Footer';
import SalonListPage from '../components/HomeLanding/SalonListPage';


const SalonViewPage:React.FC  =()=>{
    return (
        <main className="">
            <NavBarHome/>
        <section className="">
            
        <div className="pt-[32px] h-full mx-5 md:mx-7 lg:mx-16 xl:mx-24 md:pt-10  lg:pt-42  ">
            <SalonListPage/>
        </div>    
            <div className="pt-20">
                <Footer />
            </div>
        </section>
        </main>
    )
}

export default SalonViewPage;