// store/slices/packagesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExtPackage, Package } from '../../types/salonServicesTypes';


interface PackagesState {
  loading: boolean;
  error: string | null;
  packages: ExtPackage[] | null;
  recommendedPackages: ExtPackage[];
}


const initialState: PackagesState = {
  loading: true,
  error: null,
  packages: null,
  recommendedPackages: [],
};

const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    fetchPackagesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchPackagesSuccess(state, action: PayloadAction<ExtPackage[]>) {
      state.loading = false;
      state.packages = action.payload;
    },
    fetchPackagesFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setRecommendedPackages(state, action: PayloadAction<ExtPackage[]>) {
      state.recommendedPackages = action.payload;
    },
    clearRecommendedPackages(state) {
      // Reset to initial state
      state.recommendedPackages = [];
    },
  },
});

export const {
  fetchPackagesStart,
  fetchPackagesSuccess,
  fetchPackagesFailure,
  setRecommendedPackages,
  clearRecommendedPackages
} = packagesSlice.actions;

export default packagesSlice.reducer;
