import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
 
import { Form, Link } from "react-router-dom";
import axios from "axios";
// import SuccessModal from "../../ui/SuccessModel";
// import ContactusForm2 from "./ContactusForm";

// Define types for form data
interface FormField {
    name: string;
    label: string;
    placeholder?: string;
    isDrop?: boolean;
    required?: boolean;
}

interface ContactusFormProps {
    formData: FormField[];
    dropValue: string[];
    typeReq: [string, React.Dispatch<React.SetStateAction<string>>];
    custClass?: string;
}

// Section1 Component
const Section1: React.FC = () => {
    return (
        <div className="">
            <div className="bg-hero h-full text-white pb-20 lg:pb-40 ">
                <h2 className="lg:text-5xl font-lexend font-bold text-center pt-7 md:pt-10 lg:pt-40">
                    Let's Beautify Your Connection!
                </h2>
                <p className="text-center font-mukta text-sm lg:text-2xl px-3 leading-relaxed lg:px-0 pt-2 lg:pt-5">
                    At Stylin, every interaction is a <br className="lg:hidden" /> brushstroke on the canvas
                    of beauty.
                </p>
            </div>
            <div className="flex justify-center items-center">
                <div className="w-full md:w-[70%] lg:w-[50%] mx-5 md:mx-0 bg-white rounded-xl shadow-lg -mt-10 lg:-mt-24 px-5 lg:px-0">
                    {/* <ContactusForm  /> */}
                </div>
            </div>
        </div>
    );
};

export default Section1;

// ContactusForm Component
export const ContactusForm: React.FC<ContactusFormProps> = ({
    formData = [],
    dropValue = [],
    typeReq,
    custClass = "w-[50%]",
}) => {
    const [inputs, setInputs] = useState<Record<string, string>>({});
    const [formErrors, setFormErrors] = useState<Record<string, string | undefined>>({});
    const [messageError, setMessageError] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [formtype, setFormtype] = typeReq;

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, fieldName: string) => {
        setInputs({
            ...inputs,
            [fieldName]: e.target.value,
        });
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: undefined,
        }));
    };

    const validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    useEffect(() => {
        // You can add email validation or other logic if necessary
    }, [inputs]);

    const validateField = (fieldName: string, value: string | undefined): boolean => {
        const field = formData.find((f) => f.name === fieldName);

        if (field?.required && !value) {
            return false;
        }

        return true;
    };

    const validateForm = (): boolean => {
        let isValid = true;
        const newErrors: Record<string, string> = {};

        for (const field of formData) {
            const value = inputs[field.name];
            if (!validateField(field.name, value)) {
                newErrors[field.name] = `${field.label} is required`;
                isValid = false;
            }
        }

        setFormErrors(newErrors);

        return isValid;
    };

    useEffect(() => {
        setInputs({});
    }, [formtype]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            // Form submission logic here
            setInputs({});
        }

        if (inputs["message"]) {
            setMessageError(false);
        } else {
            setMessageError(true);
        }

        const salondata = {
            fullname: inputs.fullname,
            whatsapp: inputs.contactno,
            salonname: inputs.salonname,
            salontype: inputs.salontype,
            email: inputs.email,
            location: inputs.location,
            yearsinoperation: inputs.yearsinope,
            medialink: inputs.socialmedia,
            message: inputs.message,
        };

        const branddata = {
            fullname: inputs.fullname,
            whatsapp: inputs.contactno,
            brandname: inputs.brandname,
            brandtype: inputs.brandtype,
            email: inputs.email,
            location: inputs.place,
            yearsinoperation: inputs.yearsinope,
            medialink: inputs.socialmedia,
            message: inputs.message,
        };

        const featureddata = {
            fullname: inputs.fullname,
            email: inputs.email,
            number: inputs.mobile,
            state: inputs.state,
            message: inputs.message,
        };

        if (Object.keys(inputs).length > formData.length && formtype === "salonform") {
            axios.post("https://sheetdb.io/api/v1/8js046f57oxan", salondata)
                .then((res) => {
                    if (res.status === 201) {
                        setShowSuccessModal(true);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                    }
                })
                .catch((err) => console.log(err));
        }

        if (Object.keys(inputs).length > formData.length && formtype === "brandform") {
            axios.post("https://sheetdb.io/api/v1/asjghevkytrbl", branddata)
                .then((res) => {
                    if (res.status === 201) {
                        setShowSuccessModal(true);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                    }
                })
                .catch((err) => console.log(err));
        }

        if (Object.keys(inputs).length > formData.length && formtype === "featuredform") {
            axios.post("https://sheetdb.io/api/v1/77v7drt9if80x", featureddata)
                .then((res) => {
                    if (res.status === 201) {
                        setShowSuccessModal(true);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    return (
        <>
            {showSuccessModal && (
                <div className="absolute top-0 h-screen m-auto flex justify-center bg-[#020202b8] z-10 w-full left-0">
                    {/* <SuccessModal
                        triggerButtonRef={handleSubmit}
                        closeBox={[showSuccessModal, setShowSuccessModal]}
                    /> */}
                </div>
            )}

            <form
                className={`bg-white ${custClass} -z-0 m-auto text-[#020202] p-4 lg:p-10 rounded-lg drop-shadow-lg`}
                onSubmit={handleSubmit}
            >
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                    {formData.map((item, key) => (
                        <div key={key} className="flex flex-col space-y-">
                            <label
                                htmlFor={item.name}
                                className={`text-base font-lexend ${item.isDrop ? "hidden" : ""}`}
                            >
                                {item.label}
                                {item.required !== false && <span className="text-[#de594a]">*</span>}
                            </label>
                            {!item.isDrop && (
                                <input
                                    type="text"
                                    id={item.name}
                                    name={item.name}
                                    value={inputs[item.name] || ""}
                                    placeholder={item.placeholder}
                                    onChange={(e) => handleChange(e, item.name)}
                                    className="border rounded-md drop-shadow-md mt-2 py-1 px-2 lg:w-60 border-[#625f5f6e] focus:outline-none"
                                />
                            )}

                            {item.isDrop && (
                                <select
                                    className="mt-2 border rounded-md drop-shadow-md py-1 px-2 lg:w-60 border-[#625f5f6e] focus:outline-none"
                                    onChange={(e) => handleChange(e, item.name)}
                                >
                                    {dropValue.map((items, key) => (
                                        <option key={key} value={items}>
                                            {items}
                                        </option>
                                    ))}
                                </select>
                            )}

                            <span style={{ color: "red" }}>
                                {formErrors[item.name]}
                            </span>
                        </div>
                    ))}
                </div>

                <div className="pt-5 space-y-2">
                    <label htmlFor="message" className="font-lexend">
                        Your Message <span className="text-[#de594a]">*</span>
                    </label>
                    <textarea
                        name="message"
                        id="message"
                        cols={30}
                        rows={10}
                        value={inputs["message"] || ""}
                        onChange={(e) => handleChange(e, "message")}
                        className="border rounded-md drop-shadow-md py-1 px-2 w-full border-[#625f5f6e] focus:outline-none"
                    />
                    <span style={{ color: "red" }}>
                        {messageError ? "This field is required" : ""}
                    </span>
                </div>

                <div className="pt-5">
                    <label className="font-lexend inline-flex items-center">
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={() => setIsChecked(!isChecked)}
                            className="w-4 h-4 accent-[#de594a] focus:ring-transparent border-[#625f5f6e] bg-[#020202]"
                        />
                        <span className="pl-2">
                            I agree to the <Link to="/FAQ" className="underline text-[#de594a]">
                                terms and conditions
                            </Link>
                        </span>
                    </label>
                </div>

                <div className="flex justify-center lg:justify-start pt-8">
                    <button
                        disabled={!isChecked}
                        className="bg-[#de594a] font-mukta w-full lg:w-40 text-lg font-regular hover:bg-[#000] hover:duration-500 py-2 rounded-md text-white"
                        type="submit"
                    >
                        Send
                    </button>
                </div>
            </form>
        </>
    );
};
