// store/slices/shopOffersSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SalonOffers } from '../../types/salonDetailsTypes';

 
interface ShopOffersState {
  loading: boolean;
  error: string | null;
  offers: SalonOffers[] | null;
}

const initialState: ShopOffersState = {
  loading: true,
  error: null,
  offers: null,
};

const shopOffersSlice = createSlice({
  name: 'shopOffers',
  initialState,
  reducers: {
    fetchOffersStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchOffersSuccess(state, action: PayloadAction<SalonOffers[]>) {
      state.loading = false;
      state.offers = action.payload;
    },
    fetchOffersFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchOffersStart,
  fetchOffersSuccess,
  fetchOffersFailure,
} = shopOffersSlice.actions;

export default shopOffersSlice.reducer;
