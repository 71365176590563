import React from 'react';

interface IPopupLayout {
    children: React.ReactNode;

}

const PopUpLayout : React.FC<IPopupLayout> = ({children})=> {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="relative  ">
                {children}
            </div>
        </div>
    )
}


export default PopUpLayout;