import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
// import { IoManSharp, IoWoman } from "react-icons/io5";
import { IDropdownPPProps } from "../../types/userAuthTypes";
import filter from "../../assets/service/filter_icon.png";

const DropdownPP: React.FC<IDropdownPPProps> = ({
  custClsName,
  onChangeFun = () => {},
  options,
  isSpecFunType,
  dropDefVal,
  value,
}) => {
  const [isDropOpen, setIsDropOpen] = useState<boolean>(false);

  const handleDropOpn = () => {
    setIsDropOpen(!isDropOpen);
  };

  const handleSelect = (option: string) => {
    if (onChangeFun) {
      onChangeFun(option);
    }
    setIsDropOpen(false); // Close dropdown after selection
  };

  return (
    <div
      className={`${custClsName} flex items-center md:gap-3 lg:gap-3 border border-[#B2B2B2] rounded-[4px] px-2.5 md:px-4 md:py-[9px] py-2 relative cursor-pointer z-40 md:w-[144px] md:h-[40px] lg:w-[144px] lg:h-[40px] lg:rounded-[4px] lg:py-[9px] lg:px-[16px]`}
      onClick={handleDropOpn}
    >
      <div className={`${isSpecFunType === "gender" ? "flex md:hidden" : "hidden"} items-center w-6 h-6 rounded-[4px]`}>
        {/* <IoManSharp />
        <IoWoman className="-ml-1.5" /> */}
        <img src={filter} alt="filter icon" className="w-full h-full object-cover" />
      </div>
      <p className={`${isSpecFunType === "gender" ? "hidden md:block" : ""} w-max text-DARKEST_GREY_L1 font-medium capitalize lg:text-base lg:font-medium `}>
        {value==''?dropDefVal:value}
      </p>
      <div className="flex flex-1" />
      {isDropOpen ? (
        <FaChevronUp className={`${isSpecFunType === "gender" ? "" : ""} hidden md:block text-[#B2B2B2]`} />
      ) : (
        <FaChevronDown className={`hidden md:block text-[#B2B2B2]`} />
      )}

      <div
        className={`${isDropOpen ? "z-50" : "hidden"} ${
          isSpecFunType === "gender" ? "-left-14 md:left-0 w-max md:w-full" : "left-0 w-full"
        } top-[42px] bg-[#FEFFFF] absolute rounded-md z-50`}
        style={{
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
        }}
      >
        {options?.map((option, index) => (
          <div className="hover:bg-DARKEST_GREY_L4" onClick={() => handleSelect(option)} key={index}>
            <p className="text-sm md:text-base px-3 pt-3 pb-3 md:pb-6">
              {`${isSpecFunType === "gender" ? "For " : ""}${option}`}
            </p>
            <hr className={`${index + 1 === options.length && "hidden"} text-DARKEST_GREY_L3`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownPP;
